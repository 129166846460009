<template>
    <ErrorModal />
    <Toast /> 
    <div class="card-container">
        <div class="inline-block">
            <h2 class="header">Work Orders <Button type="button" icon="pi pi-refresh" class="p-button-text p-button-rounded" @click="loadWordOrders()" /></h2>
        </div>
        <div class="inline-block search">
            <span class="p-input-icon-left field-container">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Search" />
                <Button type="button" icon="pi pi-times" class="p-button-text p-button-rounded clear" @click="clearFilter()" />
            </span>
        </div>
    </div>
    <div class="container flex-grow-1 workorders">
        <DataTable :value="itemlist" :paginator="true" :rows="10" stripedRows
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]" breakpoint="640px"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :filters="filters" selectionMode="single"
        @rowSelect="onRowSelect" :loading="loading" dataKey="WorkOrderNumber" v-if="!empty">
            <template #loading>
                <span>Loading workorders <i class="pi pi-spin pi-spinner"></i></span>
            </template>
            <Column field="WorkOrderNumber" header="WO #" :sortable="true"></Column>
            <Column field="FullName" header="Full Name" :sortable="true"></Column>
            <Column field="Plate" header="Plate #" :sortable="true"></Column>
            <Column field="Make" header="Make" :sortable="true"></Column>
            <Column field="Model" header="Model" :sortable="true"></Column>
            <Column field="Color" header="Color" :sortable="true"></Column>
            <Column field="VIN" header="VIN" :sortable="true"></Column>
        </DataTable>
        <div v-if="empty" class="container flex-grow-1">No work orders found.</div>
    </div>
</template>

<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { ref } from "@vue/reactivity";
import ApiService from '../service/api.service';
import { onMounted, onBeforeMount, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "../store";
import { MutationTypes } from "../store/mutations";
import { WOItem } from '../store/state';
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import ErrorModal from './ErrorModal.vue';
import { openErrorModal } from '@/composables/useErrorLog';

const toast = useToast();
const auth0 = useAuth0();
const items = ref([]);
const filters = ref({
  'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const storeid = ref('');
const role = ref('');
const storestatus = ref('');
const loading = ref(false);
const empty = ref(false);
const router = useRouter();
const itemlist = computed(() => items.value.map((b) => {
  b.FullName = b.FirstName + " " + b.LastName;
  return b;
}));

onBeforeMount(async () => {
    const auth0 = useAuth0();
    const accessToken = await auth0.getAccessTokenSilently();
    storeid.value = await ApiService.getVuexStoreid(accessToken);
    role.value = await ApiService.getVuexRole(accessToken);
    storestatus.value = await ApiService.getStoreStatus(accessToken,storeid.value);
    if (storestatus.value == 'Disable')
    router.push('/storedisable');
});

onMounted(async () => {
    const store = useStore();
    const woitems: WOItem[] = store.state.woitems;
    if (woitems.length > 0) {
        items.value = woitems;
    } else {
        loadWordOrders();
    }
});

async function loadWordOrders() {
    loading.value = true;
    const store = useStore();
    const accessToken = await auth0.getAccessTokenSilently();
    const storeid = await ApiService.getVuexStoreid(accessToken);
    const WOurl = `${process.env.VUE_APP_NODEJS_BASE_URL}workorders/${storeid}`;
    try {
        const response = await fetch(WOurl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        });
        loading.value = false;
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
        const datamessage = data.message;
        store.commit(MutationTypes.SET_WOITEMS, datamessage);
        items.value = datamessage;
        if ((items.value.length) < 1) {
            empty.value = true;
        } else {
            empty.value = false;
        }
    } catch (e) {
        empty.value = true;
        openErrorModal(e.message);
    }
}

function clearFilter() {
    initFilters();
}

function initFilters() {
    filters.value = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
}

const onRowSelect = (event) => {
    toast.add({ severity: 'info', summary: 'WO Selected', detail: 'Name: ' + event.data.WorkOrderNumber, life: 3000 });
    router.push({ name: 'updateorder', params: { workordernumber: event.data.WorkOrderNumber } });
};

</script>
<style>
.container.no-pad {
    padding-right: 0;
    padding-left: 0;
}

button {
    border: none;
    background-color: transparent;
    padding: 1rem;
}

.input-group-text {
    background-color: #cee2fe;
    border: 1px solid #c3dcff;
}

.input-group input {
    border: 1px solid #c3dcff;
}

.table-bordered thead,
.table-bordered th {
    border: 1px solid #184b89 !important;
    background-color: #cee2fe;
    font-weight: normal;
}

.table-bordered tbody {
    border-top: 1px solid #184b89;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f2f8ff;
}

.table-bordered td {
    border: 1px solid #c3dcff;
}

.btn-outline {
    border: 1px solid #626262;
    color: #626262;
}
</style>
