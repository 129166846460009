<template>
    <ErrorModal />
    <Toast />
    <ConfirmDialog></ConfirmDialog>

    <div class="card-container inspection">
        <div class="title">
            <h2 class="header">Inspection Summary</h2>
        </div>
        <div class="customer">
            <Button @click="handleEdit" label="Continue Editing" class="p-button p-component p-button-rounded btn-secondary" />
            <Button @click="handleSubmit" :loading="loading" :label="checkResults ? 'Submit Inspection' : 'Save Inspection'"
                class="p-button p-component p-button-rounded btn-primary ml-2" />
        </div>
    </div>

    <div class="container summary">

        <div class="wo-details">
            <button @click="handleCancel"
                class="p-button p-component p-button-rounded btn-primary sml flex">Workorder&nbsp;{{ workordernumber }}</button>
            <p class="customer">
                <span><strong>Customer:</strong> {{ firstname }}&nbsp;{{ lastname }} </span>
                <span><strong>Vehicle:</strong> {{ color }}&nbsp;{{ make }}&nbsp;{{ model }} </span>
                <span><strong>Plate:</strong> {{plate}} </span>
                <span><strong>Odometer:</strong> {{UsageComputed}}</span>
            </p>
        </div>

        <div class="p-datatable p-component p-datatable-responsive-stack p-datatable-striped" data-scrollselectors=".p-datatable-wrapper">
            <div class="p-datatable-header" style="border-radius: 25px 25px 0px 0px !important;">
                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                    <h4 style="margin-bottom: 0px;">
                        <strong>{{ workorderpackageheader }} Summary</strong>
                    </h4>
                </div>
            </div>

            <div class="p-datatable-wrapper">
                <table role="table" class="p-datatable-table">
                    <thead class="p-datatable-thead" role="rowgroup">
                        <tr role="row">
                            <th class="item" role="cell" style="border-radius: 0px !important;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">Item</span>
                                </div>
                            </th>
                            <th @click="handleSorting" class="result" role="cell" style="cursor: pointer; border-radius: 0px !important;">
                                <div :style="{color: sortResult != '' ? '#2196F3' : ''}" class="result" style="width:6rem;line-height: 1.5;">
                                    <span class="p-column-title">Result</span>
                                    <span v-if="sortResult === ''" class="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
                                    <span v-if="sortResult === 'asc'" class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"></span>
                                    <span v-if="sortResult === 'desc'" class="p-sortable-column-icon pi pi-fw pi-sort-amount-down"></span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="p-datatable-tbody" role="rowgroup">
                        <tr v-for="(InspectionLine, inspectionIndex) in sortData(currentWorkOrderPackageInspectionLinesRef)" :key='inspectionIndex' class role="row" draggable="false" :style="{ display: (InspectionLine.Type === 'Header' && sortResult !== '') ? 'none' : '' }">
                            <td class="item" role="cell">
                                <span class="p-column-title">Item</span>
                                <div v-if="InspectionLine.Type === 'Header' && sortResult === ''" class="row" style="display:flex;justify-content:space-between;">
                                    <div class="title" style="flex-grow:1;">
                                        <strong>{{ InspectionLine.Title }}</strong>
                                    </div>
                                </div>
                                <div v-if="InspectionLine.Type === 'Line'" class="row" style="display:flex;justify-content:space-between;">
                                    <div class="title" style="flex-grow:1;">
                                        <h4 class="title">{{ InspectionLine.Title }}</h4>
                                        <div v-if="InspectionLine.Comment" class="notes" style="margin-left:2rem;">
                                            <p style="margin-bottom:0;"><strong>Comment:</strong> {{ InspectionLine.Comment }}</p>
                                        </div>
                                        <div v-if="InspectionLine.Notes" class="notes" style="margin-left:2rem;">
                                            <p style="margin-bottom:0;"><strong>Notes:</strong> {{ InspectionLine.Notes }}</p>
                                        </div>
                                        <div v-if="InspectionLine.ShopNotes" class="notes" style="margin-left:2rem;">
                                            <p style="margin-bottom:0;"><strong>Internal Notes:</strong> {{ InspectionLine.ShopNotes }}</p>
                                        </div>
                                        <div v-if="InspectionLine.Unit !== ''" class="notes" style="margin-left:2rem;">
                                            <p style="margin-bottom:0;"><strong>Measurements: </strong>
                                                LF: {{ InspectionLine.LF + InspectionLine.UnitText }}
                                                RF: {{ InspectionLine.RF + InspectionLine.UnitText }}
                                                LR: {{ InspectionLine.LR + InspectionLine.UnitText }}
                                                RR: {{ InspectionLine.RR + InspectionLine.UnitText }}
                                            </p>
                                        </div>
                                        <div v-if="InspectionLine.MediaKeys.length" class="documents" style="margin-left:2rem;">
                                            <p style="margin-bottom:0;"><strong>Upload(s):</strong></p>
                                            <div class="p-image p-image-preview-container item-image" v-for="(media, mediaKeyIndex) in InspectionLine.MediaKeys"
                                                v-bind:key="mediaKeyIndex" style="margin-right:1.5rem;">
                                                <!-- check if the media key has delimiter -->
                                                <template v-if="media.includes('|') === false"> 
                                                    <Image
                                                        :inspection-index="inspectionIndex"
                                                        :media-index="mediaKeyIndex"
                                                        class="p-overlay-badge"
                                                        alt="Image"
                                                        width="150"
                                                        preview
                                                        :src="media"
                                                        @error="handleImageError(inspectionIndex, mediaKeyIndex, '/default_no_image.png')"
                                                    />
                                                    <Button class="p-button p-button-rounded btn-danger sml delete"
                                                        @click="deleteImage(InspectionLine.WorkOrderPackageInspectionLineID, mediaKeyIndex, media)">X</button>
                                                </template>
                                                <template v-else>
                                                    <div class="video-container">
                                                        <i class="pi pi-play"></i>
                                                        <Image
                                                            :inspection-index="inspectionIndex"
                                                            :media-index="mediaKeyIndex"
                                                            alt="Image"
                                                            width="150"
                                                            :src="getThumbnail(media)"
                                                            @click="event => openVideoPopup(event, media)"
                                                            @error="handleImageError(inspectionIndex, mediaKeyIndex, '/default_no_video.png')"
                                                        />
                                                        <Button class="p-button p-button-rounded btn-danger sml delete"
                                                            @click="deleteVideo(InspectionLine.WorkOrderPackageInspectionLineID, mediaKeyIndex, media)">X</Button>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="result" role="cell">
                                <!-- <span class="p-column-title">Result</span> -->
                                <span v-if="InspectionLine.Type === 'Line'" :class="'customer-badge status-' + InspectionLine.Result">{{ InspectionLine.Result }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="preview-modal" id="PreviewModal">
        <span class="close" id="closeButton" @click="closeVideoPopup">&times;</span>
        <img class="modal-content" id="previewNoVideo" src="/default_no_video.png" alt="Image" style="display: none;">
        <video class="modal-content" id="previewVideo" style="display:none" controls autoplay>
            <source />
        </video>
    </div>

    <Button @click="topFunction" id="topBtn" icon="pi pi-arrow-up" class="p-button-rounded" aria-label="Go to Top" />
</template>

<script  lang="ts" setup>
import { computed, onMounted } from 'vue';
import { ref } from "@vue/reactivity";
import { WorkOrderPackage } from '../store/state';
import { useRouter } from 'vue-router';
import { useAuth0 } from "@auth0/auth0-vue";
import ApiService from '../service/api.service';
import { MutationTypes } from "../store/mutations";
import { useStore } from "../store";
import { useToast } from 'primevue/usetoast';
import Image from 'primevue/image';
import ErrorModal from './ErrorModal.vue';
import { openErrorModal } from '@/composables/useErrorLog';

const store = useStore();
const loading = ref(false);
const auth0 = useAuth0();
const router = useRouter();
const toast = useToast();
const workorderpackageid = computed(() => store.state.currentWorkOrderPackageID);
const workorderpackageheader = computed(() => store.state.currentWorkOrderPackageHeader);
const workorderid = ref('');
const workordernumber = ref('');
const lastname = ref('');
const firstname = ref('');
const make = ref('');
const model = ref('');
const color = ref('');
const plate = ref('');
const usage = ref();
const UsageComputed = computed(() => new Intl.NumberFormat().format(usage.value));
const Username = ref('');
let currentWorkOrderPackage: WorkOrderPackage[] | undefined = [];
const currentWorkOrderPackageInspectionLinesRef = ref();
const sortResult = ref('');
const currentDVIWO = computed(() => store.state.currentdviwoitem);
const dataSorted = ref([]);
const checkResults = computed(() => {
    return currentWorkOrderPackageInspectionLinesRef.value === undefined ? false :
        currentWorkOrderPackageInspectionLinesRef.value
        .filter((item) => item.Type === 'Line')
        .every((item) => item.Result.trim() !== '');
})

/**
 * Set data, filter work orders and packages
 */
onMounted(async () => {
    window.addEventListener('scroll', handleScroll);

    const accessToken1 = await auth0.getAccessTokenSilently();
    Username.value = await ApiService.getVuexUsername(accessToken1);

    if (currentDVIWO.value !== undefined) {
        workorderid.value = currentDVIWO.value?.WorkOrderID;
        workordernumber.value = currentDVIWO.value?.WorkOrderNumber.toString();
        lastname.value = currentDVIWO.value?.LastName;
        firstname.value = currentDVIWO.value?.FirstName;
        make.value = currentDVIWO.value?.Make;
        model.value = currentDVIWO.value?.Model;
        color.value = currentDVIWO.value?.Color;
        plate.value = currentDVIWO.value?.Plate;
        usage.value = currentDVIWO.value?.InUsage;
    }

    currentWorkOrderPackage = currentDVIWO.value?.WorkOrderPackages?.filter(obj => obj.WorkOrderPackageID === workorderpackageid.value);
    if (currentWorkOrderPackage !== undefined) {
        currentWorkOrderPackageInspectionLinesRef.value = currentWorkOrderPackage[0].WorkOrderPackageInspectionLines;
    } else {
        currentWorkOrderPackageInspectionLinesRef.value = [];
    }
});

/**
 * To display tob button
 */
const handleScroll = () => {
    const topButton = document.getElementById("topBtn");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        topButton.style.display = "flex";
    } else {
        topButton.style.display = "none";
    }
}

/**
 * Makes the page to move at the top
 */
const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

/**
 * Navigate to update page if handleEdit has been clicked
 */
const handleEdit = () => {
    router.push({ name: 'dviupdateworkorderpackage', params: { workorderpackageid: workorderpackageid.value, workorderpackageheader: workorderpackageheader.value } });
}

/**
 * Actual saving of the data
 */
 const handleSubmit = async () => {
    if (currentWorkOrderPackage !== undefined) {
        loading.value = true;
        currentWorkOrderPackage[0].WorkOrderPackageInspectionLines = currentWorkOrderPackageInspectionLinesRef.value;
        store.commit(MutationTypes.UPDATE_DVIWOPACKAGE, { WorkOrderNumber: workordernumber.value, WorkOrderPackageID: workorderpackageid.value, WorkOrderPackage: currentWorkOrderPackage[0] });
        const accessToken = await auth0.getAccessTokenSilently();
        const storeid = await ApiService.getVuexStoreid(accessToken);
        const apiserver = process.env.VUE_APP_NODEJS_BASE_URL;
        const url = apiserver + "dviworkorders/" + storeid + "/" + workorderid.value + "/" + workorderpackageid.value;
        const origin = new URL(apiserver).origin;

        //post json from state to integration api
        let WorkOrderPackageInspectionLinesdata = {
            WorkOrderPackageInspectionLines: currentWorkOrderPackage[0].WorkOrderPackageInspectionLines,
            resultPageURL: origin + "/results.html"
        };

        try {
            const response: Response = await fetch(url, {
                method: 'PATCH',
                body: JSON.stringify(WorkOrderPackageInspectionLinesdata),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
            });

            const data = await response.json();
            if (response.status > 400) {
                throw new Error(JSON.stringify(data.error));
            }
            const popupmessage = checkResults.value ? 'Inspection marked as Completed.' : 'Inspection has been Saved.';
            toast.add({ severity: 'success', summary: 'Successful', detail: popupmessage, life: 3000 });
            loading.value = false;
        } catch (e) {
            openErrorModal(e.message);
            loading.value = false;
        }
    }
}

/**
 * Cancel will navigate the user to dviworkorderpackages view component 
 */
function handleCancel() {
    router.push({ name: 'dviworkorderpackages', params: { workordernumber: workordernumber.value, workorderid: workorderid.value } });
}

/**
 * Delete Image in S3
 * @param WorkOrderPackageInspectionLineID 
 * @param Imageindex 
 * @param ImageFullUrl 
 */
const deleteImage = async (WorkOrderPackageInspectionLineID: string, Imageindex: number, ImageFullUrl: string) => {
    deleteImageInVuex(Imageindex, WorkOrderPackageInspectionLineID);
    //delete image from db through integration API
    await deleteImageIntegrationAPI(WorkOrderPackageInspectionLineID, ImageFullUrl);
    await deleteImageInAWSS3(ImageFullUrl);
}

/**
 * Delete image in vuex
 * @param ImageFullUrl 
 */
 const deleteImageInVuex = (index: number, WorkOrderPackageInspectionLineID: string) => {
    //delete image from array ref memory
    currentWorkOrderPackageInspectionLinesRef.value.filter(obj => obj.WorkOrderPackageInspectionLineID === WorkOrderPackageInspectionLineID)[0].MediaKeys.splice(index, 1);

    //delete image from VUEX state
    if (currentWorkOrderPackage !== undefined) {
        currentWorkOrderPackage[0].WorkOrderPackageInspectionLines = currentWorkOrderPackageInspectionLinesRef.value;

        //update currentWorkOrderPackage in state
        store.commit(MutationTypes.UPDATE_DVIWOPACKAGE, { WorkOrderNumber: workordernumber.value, WorkOrderPackageID: workorderpackageid.value, WorkOrderPackage: currentWorkOrderPackage[0] });
    }
}

/**
 * Delete image in API server
 * @param WorkOrderPackageInspectionLineID 
 * @param ImageFullUrl 
 */
 const deleteImageIntegrationAPI = async (WorkOrderPackageInspectionLineID: string, ImageFullUrl: string) => {
    let mediadata = {
        MediaKey: ImageFullUrl,
        Username: Username.value
    };

    const accessToken = await auth0.getAccessTokenSilently();
    const storeid = await ApiService.getVuexStoreid(accessToken);
    const url = process.env.VUE_APP_NODEJS_BASE_URL + "dviworkorders/s3mediakey/" + storeid + "/" + WorkOrderPackageInspectionLineID;

    try {
        const response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(mediadata),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
    } catch (e) {
        openErrorModal(e.message);
    }
}

/**
 * Delete image in AWS S3
 * @param ImageFullUrl 
 */
 const deleteImageInAWSS3 = async (ImageFullUrl: string) => {
    const accessToken = await auth0.getAccessTokenSilently();
    const storeid = await ApiService.getVuexStoreid(accessToken);
    const deletedMediaKey = ImageFullUrl.split("/")[5];
    const s3url = process.env.VUE_APP_NODEJS_BASE_URL + "s3/deleteobject/" + storeid + "/"  + workordernumber.value + "/" + deletedMediaKey;
    try {
        const response = await fetch(s3url, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
    } catch (e) {
        openErrorModal(e.message);
    }
}

/**
 * To open video popup
 * @param event 
 * @param media 
 */
const openVideoPopup = async (event, media) => {

    if (event.target.src.endsWith('/default_no_video.png') === true) {
        return;
    }
    
    const modalImage = document.getElementById("previewNoVideo");
    modalImage.style.display = 'none';

    const previewModal = document.getElementById("PreviewModal");
    previewModal.style.display = 'block';

    const modalVideo = document.getElementById("previewVideo");
    modalVideo.style.display = 'block';

    const baseUrl = getVideo(media); // https://api-files.sproutvideo.com/file/0690d2b11d1aefcf8f/7d38b83fb71f18a6/
    const resolutions = ["720", "480", "360", "240"];
    const videoAssets = resolutions.map(resolution => `${baseUrl}${resolution}.mp4`);
    let isValid = false;
    for (let index=0; index < videoAssets.length; index++) {
        const response = await fetch(videoAssets[index]);
        if (response.status >= 200 && response.status < 400) {
            modalVideo.src = videoAssets[index];
            isValid = true;
            break; // this is to stop the iteration
        }
    }
    
    if (isValid === false) {
        modalVideo.style.display = 'none';
        modalImage.style.display = 'block';
    }
}

/**
 * To close popup video
 */
const closeVideoPopup = () => {
    let previewModal = document.getElementById("PreviewModal");
    previewModal.style.display = 'none';
    let modalVideo = document.getElementById("previewVideo");
    modalVideo.src = null;
}

/**
 * Split the media file and return thumbnail
 * @param media 
 */
const getThumbnail = (media: string) => {
    return media.split('|')[0];
}

/**
 * Get video of given string
 * @param media 
 */
const getVideo = (media: string) => {
    return media.split('|')[1];
}

/**
 * Actual delete of video
 * @param WorkOrderPackageInspectionLineID 
 * @param mediaKeyIndex 
 * @param media 
 */
const deleteVideo = async (WorkOrderPackageInspectionLineID: string, mediaKeyIndex: number, media: string) => {
    const videoUrl = getVideo(media);
    
    // get video id using video url
    const videoArray = videoUrl.split('/');
    const videoID = videoArray[videoArray.length - 3];

    deleteVideoInVuex(WorkOrderPackageInspectionLineID, mediaKeyIndex);
    //delete image from db through integration API
    await deletVideoIntegrationAPI(WorkOrderPackageInspectionLineID, media);
    await deleteVideoInSproutAPI(videoID);
}

/**
 * deletVideoOnIntegrationAPI
 * @param WorkOrderPackageInspectionLineID 
 * @param media 
 */
const deletVideoIntegrationAPI = async (WorkOrderPackageInspectionLineID: string, media: string) => {
    let mediadata = {
        MediaKey: media,
        Username:Username.value
    };

    const accessToken = await auth0.getAccessTokenSilently();
    const storeid = await ApiService.getVuexStoreid(accessToken);
    const url = process.env.VUE_APP_NODEJS_BASE_URL + "dviworkorders/s3mediakey/" + storeid + "/" + WorkOrderPackageInspectionLineID;

    try {
        const response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(mediadata),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
    } catch (e) {
        openErrorModal(e.message);
    }
}

/**
 * Delete video in vuex
 * @param WorkOrderPackageInspectionLineID 
 * @param mediaKeyIndex
 */
const deleteVideoInVuex = (WorkOrderPackageInspectionLineID: string, mediaKeyIndex: number) => {
    //delete image from array ref memory
    currentWorkOrderPackageInspectionLinesRef.value.filter((obj) => obj.WorkOrderPackageInspectionLineID === WorkOrderPackageInspectionLineID)[0].MediaKeys.splice(mediaKeyIndex, 1);

    //delete image from VUEX state
    if (currentWorkOrderPackage !== undefined) {
        currentWorkOrderPackage[0].WorkOrderPackageInspectionLines = currentWorkOrderPackageInspectionLinesRef.value;
        //update currentWorkOrderPackage in state
        store.commit(MutationTypes.UPDATE_DVIWOPACKAGE, { WorkOrderNumber: workordernumber.value, WorkOrderPackageID: workorderpackageid.value, WorkOrderPackage: currentWorkOrderPackage[0] });
    }
}

/**
 * Delete video in sprout API
 * @param videoID 
 */
const deleteVideoInSproutAPI = async (videoID: string) => {
    try {
        const accessToken = await auth0.getAccessTokenSilently();
        const response = await fetch(process.env.VUE_APP_NODEJS_BASE_URL + 'videos/' + videoID, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
    } catch (e) {
        openErrorModal(e.message);
    }
}

const handleImageError = (inspectionIndex, mediaKeyIndex, srcImg) => {
    const errorImage: HTMLImageElement | null = document.querySelector(`img[inspection-index="${inspectionIndex}"][media-index="${mediaKeyIndex}"]`);
    if (errorImage) {
        errorImage.src = srcImg;
    }
}

/**
 * Changes the sorting value if the user clicks on the result sorting icon
 */
 const handleSorting = () => {
    switch(sortResult.value) {
        case '': sortResult.value = 'asc'
            break;
        case 'asc': sortResult.value = 'desc'
            break;
        case 'desc': sortResult.value = ''
            break;
    }
}

/**
 * Put the item on the last if the type is header
 * desc, required is the highest
 * asc, required is the lowest
 * @param a 
 * @param b 
 */
const customSort = (a, b) => {
    let order = { Required: 1, Suggested: 2, OK: 3, '': 4 };
    if (sortResult.value === 'desc') {
        order = { Required: 4, Suggested: 3, OK: 2, '': 1 };
    }
    if (a.Type === 'Header') return 1;
    if (b.Type === 'Header') return -1;
    return order[a.Result] - order[b.Result];
};

/**
 * Return original sorting which is by Rank
 * Rank is the property which considered as index in API response
 * @param a 
 * @param b 
 */
const originalSorting = (a, b) => {
    return a.Rank - b.Rank; 
}

/**
 * Sort data original, custom ascending and descending
 * @param data 
 */
const sortData = (data) => {
    if(!data) {
        dataSorted.value = [];
        return [];
    }
    if (data && sortResult.value === '') {
        const sortedData = data.sort(originalSorting);
        dataSorted.value = sortedData;
        return sortedData;
    }
    const sortedData = data.sort(customSort);
    dataSorted.value = sortedData;
    return sortedData;
}
</script>

<style>
.result .p-sortable-column-icon.pi.pi-fw.pi-sort-alt:before {
    padding-left: 0.5rem;
}

.video-thumbnail {
    cursor: pointer;
    transition: 0.3s;
}

.video-thumbnail:hover {
    opacity: 0.7;
}

.modal-content {
    margin: auto;
    display: block;
}

.modal-content, #caption {
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

.preview-modal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: filter 0.3s ease;
}
.video-container:hover {
    filter: brightness(70%);
}
.pi-play {
    position: absolute !important;
    font-size: 1.25rem !important;
    color: white !important;
}

#topBtn {
    display: none;
    position: fixed;
    z-index: 99;
    outline: none;
    cursor: pointer;
}
</style>
