import { GetterTree } from 'vuex'
import { State, WOItem,DVIWOItem } from './state'

// Getters types
export type Getters = {
  getCurrentWO(state: State): WOItem | undefined;
  getCurrentDVIWO(state: State): DVIWOItem | undefined;
};

//getters

export const getters: GetterTree<State, State> & Getters = {
  getCurrentWO: (state:State) :WOItem|undefined=> {
    
    return state.currentwoitem;
  },
  getCurrentDVIWO: (state:State) :DVIWOItem|undefined=> {
    
    return state.currentdviwoitem;
  }

};