export type WOItem = {
  WorkOrderNumber: number;
  ServiceItemID: string;
  FirstName: string;
  LastName: string;
  Make: string;
  Model: string;
  VIN: string;
  Color: string;
  Plate: string;
};

export type DVIWOItem = {
  WorkOrderID: string;
  WorkOrderNumber: number;
  ServiceItemID: string;
  FirstName: string;
  LastName: string;
  Make: string;
  Model: string;
  Color: string;
  Plate: string;
  WorkflowStage: string;
  Usage: number;
  InUsage: number;
  OutUsage: number;
  Technician: string;
  WorkOrderPackages: WorkOrderPackage[] | undefined;
};

export type WorkOrderPackage = {
  WorkOrderPackageID: string;
  WorkOrderPackageHeader: string;
  WorkOrderPackageInspectionLines: WorkOrderPackageInspectionLine[];
  
};

export type WorkOrderPackageInspectionLine = {
  WorkOrderPackageInspectionLineID: string;
  Rank: number;
  Title: string;
  Result: string;
  Comment: string;
  Notes: string;
  ShopNotes: string;
  Type: string;
  InputType: string;
  LF: number;
  RF: number;
  LR: number;
  RR: number;
  Unit: string;
  UnitText: string;
  MediaKeys: string[];
};

export type State = {
  username: string;
  storeid: string;
  role: string;
  woitems: WOItem[];
  currentwoitem: WOItem | undefined;

  dviwoitems: DVIWOItem[];
  currentdviwoitem: DVIWOItem | undefined;

  // DVIWorkOrderPackages
  currentWorkOrderNumber: string;
  currentWorkOrderID: string;

  // DVIUpdateWorkOrderPackage
  currentWorkOrderPackageID: string;
  currentWorkOrderPackageHeader: string;

  initialLogin: boolean;
};

export const state: State = {
  username: '',
  storeid: '0',
  role: '',
  woitems: [],
  currentwoitem: {} as WOItem,
  
  dviwoitems: [],
  currentdviwoitem: {} as DVIWOItem,

  /**
   * DVIWorkOrderPackages
   * Note that this items is present on currentdviwoitem however we are passing these
   * using router.push however we use these items directly once the user tries to reload the page
   */
  currentWorkOrderNumber: '',
  currentWorkOrderID: '',

  /* DVIUpdateWorkOrderPackage
   * Note that this items is present on currentdviwoitem however we are passing these
   * using router.push however we use these items directly once the user tries to reload the page
   */
  currentWorkOrderPackageID: '',
  currentWorkOrderPackageHeader: '',

  initialLogin: false
};