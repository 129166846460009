/* eslint-disable */
import { MutationTree } from 'vuex';
import { State, WOItem, DVIWOItem, WorkOrderPackage } from './state';

export enum MutationTypes {
  SET_USERNAME = "SET_USERNAME",
  SET_STOREID = "SET_STOREID",
  SET_ROLE = "SET_ROLE",
  SET_WOITEMS = "SET_WOITEMS",
  UPDATE_VIN = "UPDATE_VIN",
  SET_CURRENTWO_BYID = "SET_CURRENTWO_BYID",

  SET_DVIWOITEMS = "SET_DVIWOITEMS",
  SET_CURRENTDVIWO_BYID = "SET_CURRENTDVIWO_BYID",
  SET_DVIWOPACKAGESONCURRENTWO = "SET_DVIWOPACKAGESONCURRENTWO",
  UPDATE_DVIWOPACKAGE = "UPDATE_DVIWOPACKAGE",
  UPDATE_MEDIAKEYS = "UPDATE_MEDIAKEYS",
  UPDATE_ODOMETER = "UPDATE_ODOMETER",

  // DVIWorkOrderPackages
  SET_CURRENTWORKORDERNUMBER = "SET_CURRENTWORKORDERNUMBER",
  SET_CURRENTWORKORDERID = "SET_CURRENTWORKORDERID",

  // DVIUpdateWorkOrderPackage
  SET_CURRENTWORKORDERPACKAGEID = "SET_CURRENTWORKORDERPACKAGEID",
  SET_CURRENTWORKORDERPACKAGEHEADER = "SET_CURRENTWORKORDERPACKAGEHEADER",

  SET_INITIALLOGIN = 'SET_INITIALLOGIN'
}


//Mutation Types
export type Mutations = {
  [MutationTypes.SET_USERNAME](state: State, payload: string): void;
  [MutationTypes.SET_STOREID](state: State, payload: string): void;
  [MutationTypes.SET_ROLE](state: State, payload: string): void;
  [MutationTypes.SET_WOITEMS](state: State, woitems: WOItem[]): void;
  [MutationTypes.UPDATE_VIN](state: State, newItem: any): void;
  [MutationTypes.SET_CURRENTWO_BYID](state: State, newItemid: any): void;

  [MutationTypes.SET_DVIWOITEMS](state: State, dviwoitems: DVIWOItem[]): void;
  [MutationTypes.SET_CURRENTDVIWO_BYID](state: State, newItemid: any): void;
  [MutationTypes.SET_DVIWOPACKAGESONCURRENTWO](state: State, dviwopackages: WorkOrderPackage[]): void;

  [MutationTypes.UPDATE_DVIWOPACKAGE](state: State, newItem: any): void;
  [MutationTypes.UPDATE_MEDIAKEYS](state: State, newItem: any): void;
  [MutationTypes.UPDATE_ODOMETER](state: State, newItem: any): void;

  [MutationTypes.SET_CURRENTWORKORDERNUMBER](state: State, currentWorkOrderNumber: string): void;
  [MutationTypes.SET_CURRENTWORKORDERID](state: State, currentWorkOrderID: string): void;

  [MutationTypes.SET_CURRENTWORKORDERPACKAGEID](state: State, currentWorkOrderPackageID: string): void;
  [MutationTypes.SET_CURRENTWORKORDERPACKAGEHEADER](state: State, currentWorkOrderPackageHeader: string): void;

  [MutationTypes.SET_INITIALLOGIN](state: State, initialLogin: boolean): void;
};

//define mutations
export const mutations: MutationTree<State> & Mutations = {

  [MutationTypes.SET_USERNAME](state: State, payload: string) {

    state.username = payload;
  },
  [MutationTypes.SET_STOREID](state: State, payload: string) {

    state.storeid = payload;
  },
  [MutationTypes.SET_ROLE](state: State, payload: string) {

    state.role = payload;
  },
  [MutationTypes.SET_WOITEMS](state: State, woitems: WOItem[]) {
    state.woitems = woitems;
  },
  [MutationTypes.UPDATE_VIN](state: State, newItem: any) {
    /*
    const itemIndex = state.woitems.findIndex(s => s.ServiceItemID === newItem.ServiceItemID)
    if (itemIndex === -1) return
    state.woitems[itemIndex].VIN = newItem.VIN;
    */
    const items = state.woitems.filter(s => s.ServiceItemID === newItem.ServiceItemID);
    for (const item of items) {
      item.VIN = newItem.VIN;
      item.Plate = newItem.Plate;
    }
  },
  [MutationTypes.SET_CURRENTWO_BYID](state: State, newItemid: any) {
    state.currentwoitem = state.woitems.find((item) => item.WorkOrderNumber == newItemid);

  },
  //DVI
  [MutationTypes.SET_DVIWOITEMS](state: State, dviwoitems: DVIWOItem[]) {
    state.dviwoitems = dviwoitems;
  },

  [MutationTypes.SET_CURRENTDVIWO_BYID](state: State, newItemid: any) {
    state.currentdviwoitem = state.dviwoitems.find((item) => item.WorkOrderNumber == newItemid);

  },

  [MutationTypes.SET_DVIWOPACKAGESONCURRENTWO](state: State, dviwopackages: WorkOrderPackage[] | undefined) {
    if (state.currentdviwoitem != undefined) {
      if (dviwopackages != null && dviwopackages != undefined) {
        state.currentdviwoitem.WorkOrderPackages = dviwopackages;
      } else {
        state.currentdviwoitem.WorkOrderPackages = [];
      }
    }

  },

  [MutationTypes.UPDATE_DVIWOPACKAGE](state: State, newItem: any) {

    if (state.currentdviwoitem?.WorkOrderPackages !== undefined) {

      const items = state.currentdviwoitem?.WorkOrderPackages.filter(s => s.WorkOrderPackageID === newItem.WorkOrderPackageID);

      items[0] = newItem.WorkOrderPackage;


    }

  },

  [MutationTypes.UPDATE_MEDIAKEYS](state: State, newItem: any) {

    if (state.currentdviwoitem?.WorkOrderPackages !== undefined) {

      const items = state.currentdviwoitem?.WorkOrderPackages.filter(s => s.WorkOrderPackageID === newItem.WorkOrderPackageID);
      const workorderpackage:WorkOrderPackage = items[0];
      const items1 = workorderpackage.WorkOrderPackageInspectionLines.filter(s => s.WorkOrderPackageInspectionLineID === newItem.WorkOrderPackageInspectionLineID);
      const inspectionline = items1[0];
      inspectionline.MediaKeys = newItem.MediaKeys;

    }

  },

  [MutationTypes.UPDATE_ODOMETER](state: State, newItem: any) {

    const items = state.dviwoitems.filter(s => s.WorkOrderNumber === newItem.WorkOrderNumber);
    for (const item of items) {
      item.InUsage = newItem.InUsage;
      item.OutUsage = newItem.OutUsage;
    }

    if (state.currentdviwoitem!=null) {
      state.currentdviwoitem.InUsage = newItem.InUsage;
      state.currentdviwoitem.OutUsage = newItem.OutUsage;
    }
  },

  // DVIWorkOrderPackages
  [MutationTypes.SET_CURRENTWORKORDERNUMBER](state: State, currentWorkOrderNumber: string) {
    state.currentWorkOrderNumber = currentWorkOrderNumber;
  },
  [MutationTypes.SET_CURRENTWORKORDERID](state: State, currentWorkOrderID: string) {
    state.currentWorkOrderID = currentWorkOrderID;
  },

  // DVIUpdateWorkOrderPackage
  [MutationTypes.SET_CURRENTWORKORDERPACKAGEID](state: State, currentWorkOrderPackageID: string) {
    state.currentWorkOrderPackageID = currentWorkOrderPackageID;
  },
  [MutationTypes.SET_CURRENTWORKORDERPACKAGEHEADER](state: State, currentWorkOrderPackageHeader: string) {
    state.currentWorkOrderPackageHeader = currentWorkOrderPackageHeader;
  },

  [MutationTypes.SET_INITIALLOGIN](state: State, initialLogin: boolean) {
    state.initialLogin = initialLogin;
  },
};