<template>
    <Menubar>
        <template #start>
            <img alt="logo" class="logo" src="../assets/img/protractorCompass_revNew2.svg" height="50" />
            <div class="p-menubar-list" v-if="props.user">
                <Button class="p-menubar-item" v-for="item in props.model" @click="(event) => navigateTo(event, item.path)" :key="item.index">
                    {{ item.label }}
                </Button>
            </div>
        </template>
        <template #end>
            <span class="p-menubar-list" v-if="props.user">
                <img :src="props.user?.picture" @click="visible = true" width="50" alt="User's profile picture" class="border-circle img-fluid profile-picture" />
                <Dialog class="profile" v-model:visible="visible" modal header="Profile">
                    <div class="image">
                        <img :src="props.user?.picture" width="75" alt="User's profile picture" class="border-circle img-fluid profile-picture" />
                    </div>
                    <div class="info">
                        <h2>{{ props.user?.name }}</h2>
                        <p class="lead text-muted"><strong>Email:</strong> {{ props.user?.email }}</p>
                        <MyProfile></MyProfile>
                        <Button label="Log Out" class="logout" @click="logout" />
                    </div>
                </Dialog>
            </span>
            <span class="p-menubar-list" v-else>
                <Button class="p-menubar-item" @click="login">Login</Button>
            </span>
        </template>
    </Menubar>
</template>
  
<script lang="ts" setup>
import { ref, defineProps, inject } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { useRouter } from 'vue-router';
import MyProfile from './MyProfile.vue';
import { useStore } from 'vuex';
import { MutationTypes } from "../store/mutations";

const posthog = inject("posthog");
const auth0 = useAuth0();
const router = useRouter();
const props = defineProps(['model', 'user']);
const visible = ref(false);
const store = useStore();

/**
 * Logout using auth0
 */
const logout = () => {
    // reset the value for initial login
    store.commit(MutationTypes.SET_INITIALLOGIN, false);
    const email = auth0.user.value.email;
    posthog.capture("logout_button_clicked", { email: email });
    /**
     * Important because relogin of different credentials is just loading the old session
     */
    sessionStorage.removeItem('vuex');
    auth0.logout({
        returnTo: window.location.origin
    });
}

/**
 * Login using auth0
 * @param event 
 */
const login = (event) => {
    event.preventDefault();
    auth0.loginWithRedirect();
}

/**
 * This is for us to handle the redirection from home page to auth0 login page
 * once the user clicks login, to and from will have the same value 'home'
 */
 router.afterEach(async (to, from) => {
  if (auth0.isAuthenticated.value && (to.name === 'home' && from.name === 'home') && store.state.initialLogin === false) {
    posthog.init(process.env.VUE_APP_POSTHOG_TOKEN, { api_host: process.env.VUE_APP_POSTHOG_API_URL, enable_recording_console_log: true })
    // set true value for initial login for us to send a Posthog request once only
    store.commit(MutationTypes.SET_INITIALLOGIN, true);
    // fetch user profile to get email
    const { email, name, sub }  = { ...auth0.user.value };
    const role = auth0.user.value['http://protractor.com/role'];
    const storeid = auth0.user.value['http://protractor.com/storeid'];
    // send a PostHog request after successful authentication
    const id = sub.split('|')[1]; // auth0|qwer123tyuio456 will return qwer123tyuio456
    posthog.capture("login_button_clicked", { email: email });
    posthog.identify(id, { email: email, name: name, role: role, storeid: storeid });
  }
});

/**
 * Navigate to different pages
 * @param event 
 * @param path 
 */
const navigateTo = (event, path: string) => {
    event.preventDefault();
    router.push(path);
}
</script>
