import { ActionContext, ActionTree } from 'vuex'
import { Mutations, MutationTypes } from './mutations'
import { State } from './state'

//actions
export enum ActionTypes {
  SET_STOREID = "SET_STOREID",
  SET_ROLE = "SET_ROLE"
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

// actions interface

export interface Actions {
  [ActionTypes.SET_STOREID]({ commit }: AugmentedActionContext,payload: string): void;
  [ActionTypes.SET_ROLE]({ commit }: AugmentedActionContext,payload: string): void;
}

export const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.SET_STOREID]({ commit }, payload: string) {
    commit(MutationTypes.SET_STOREID, payload);
  },
  [ActionTypes.SET_ROLE]({ commit }, payload: string) {
    commit(MutationTypes.SET_ROLE, payload);
  } 
};
