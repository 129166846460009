<template>
    <ErrorModal />
    <Toast />
    <div class="card-container">
        <div class="inline-block">
            <h2 class="header">Work Orders <Button type="button" icon="pi pi-refresh" class="p-button-text p-button-rounded" @click="loadWorkOrders" /></h2>
        </div>
        <div class="inline-block search">
            <span class="p-input-icon-left field-container">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Search" />
                <Button type="button" icon="pi pi-times" class="p-button-text p-button-rounded clear" @click="clearFilter" />
            </span>
        </div>
    </div>
    <div class="container flex-grow-1 workorders">
        <DataTable :value="itemlist" :paginator="true" :rows="10" stripedRows
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 20, 50]" breakpoint="640px"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :filters="filters" selectionMode="single"
            @rowSelect="onRowSelect" :loading="loading" dataKey="WorkOrderNumber" v-if="!empty">
            <template #loading>
                <span>Loading workorders <i class="pi pi-spin pi-spinner"></i></span>
            </template>
            <Column field="WorkOrderNumber" header="WO #" :sortable="true"></Column>
            <Column field="FullName" header="Full Name" :sortable="true"></Column>
            <Column field="Plate" header="Plate #" :sortable="true"></Column>
            <Column field="Make" header="Make" :sortable="true"></Column>
            <Column field="Model" header="Model" :sortable="true"></Column>
            <Column field="Color" header="Color" :sortable="true"></Column>
            <Column field="InUsage" header="Odo." :sortable="true"></Column>
            <Column field="Technician" header="Tech." :sortable="true"></Column>
        </DataTable>
        <div v-if="empty" class="container flex-grow-1">No work orders found.</div>
    </div>
</template>

<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { ref } from "@vue/reactivity";
import ApiService from '../service/api.service';
import { onMounted, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "../store";
import { MutationTypes } from "../store/mutations";
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import ErrorModal from './ErrorModal.vue';
import { openErrorModal } from '@/composables/useErrorLog';

const auth0 = useAuth0();
const filters = ref({
  'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const storeid = ref('');
const role = ref('');
const storestatus = ref('');
const loading = ref(false);
const empty = ref(false);
const router = useRouter();
const route = useRoute();
const completed = route.params.completed;
const store = useStore();
const reloadInterval = ref();
const itemlist = ref();

/**
 * On before mount sets the initial data
 */
onBeforeMount(async () => {
    const auth0 = useAuth0();
    const accessToken = await auth0.getAccessTokenSilently();
    storeid.value = await ApiService.getVuexStoreid(accessToken);
    role.value = await ApiService.getVuexRole(accessToken);
    storestatus.value = await ApiService.getStoreStatus(accessToken,storeid.value);
    if (storestatus.value == 'Disable') {
        router.push('/storedisable');
    }

    /**
     * On initial load, there will be no interval if reload detail status is false
     */
    const reloadDetails = await getReloadDetails();
    handleReloadDetails(reloadDetails);
});

/**
 * Call load work order function in order to populate WO items
 */
onMounted(async () => {
    if (completed) {
        toast.add({ severity: 'success', summary: 'Successful', detail: 'Work Order Completed', life: 3000 });
    }
    await loadWorkOrders();
});

/**
 * Load work orders and assign data by VUE mutation
 */
const loadWorkOrders = async () => {
    loading.value = true;
    const accessToken = await auth0.getAccessTokenSilently();
    const storeid = await ApiService.getVuexStoreid(accessToken);
    const WOurl = process.env.VUE_APP_NODEJS_BASE_URL + "dviworkorders/" + storeid;

    try {
        const response = await fetch(WOurl, {
            headers: {
            Authorization: `Bearer ${accessToken}`,
            },
        });
        loading.value = false;
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
        let actualData = data.message;
        if (actualData === undefined) {
            actualData = [];
        }

        /**
         * Saving data to dviwoitems in VUEX is needed every time new changes were made in the list
         */
        store.commit(MutationTypes.SET_DVIWOITEMS, actualData);

        if ((actualData.length) < 1) {
            empty.value = true;
        } else {
            itemlist.value = actualData.map((b) => {
            return {
                FullName: `${b.FirstName} ${b.LastName}`,
                InUsage: new Intl.NumberFormat().format(b.InUsage),
                WorkOrderID: b.WorkOrderID,
                WorkOrderNumber: b.WorkOrderNumber,
                Plate: b.Plate,
                Make: b.Make,
                Model: b.Model,
                Color: b.Color,
                Technician: b.Technician
            };
            });
            empty.value = false;
        }
    } catch (e) {
        openErrorModal(e.message);
    }
}

/**
 * Actual API request to API server
 */
const getReloadDetails = async () => {
    const accessToken = await auth0.getAccessTokenSilently();
    const WOurl = `${process.env.VUE_APP_NODEJS_BASE_URL}application/config/1`;

    try {
        const response = await fetch(WOurl, {
            headers: {
            Authorization: `Bearer ${accessToken}`,
            },
        });
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
        return data;
    } catch (e) {
        openErrorModal(e.message);
    }
}

/**
 * if reload detail status is true, set interval of load workorder based on the period of reload detail
 * Clear interval if the reload detail status is false
 * @param reloadDetails 
 */
const handleReloadDetails = (reloadDetails) => {
    if (reloadDetails.message && reloadDetails.message.configstatus === 1) {
        if (reloadInterval.value === undefined) {
            const minute = 60000; // 1 minute in milliseconds
            reloadInterval.value = setInterval(assessReloadDetails, reloadDetails.message.parameter1 * minute);
        }
    } else if (reloadDetails.message && reloadDetails.message.configstatus !== 1) {
        clearInterval(reloadInterval.value);
    }
};

/**
 * Get reload details to see if the status has been updated
 * then load the work orders
 */
const assessReloadDetails = async () => {
    const reloadDetails = await getReloadDetails();
    handleReloadDetails(reloadDetails);
    await loadWorkOrders();
}

/**
 * Clear filter by calling initialize function
 */
const clearFilter = () => {
    initFilters();
}

/**
 * Initialize filter
 */
const initFilters = () => {
    filters.value = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    };
}

const toast = useToast();

/**
 * Push to vue router when a work order package is selected
 * @param event 
 */
 const onRowSelect = (event) => {
    store.commit(MutationTypes.SET_CURRENTWORKORDERNUMBER, event.data.WorkOrderNumber);
    store.commit(MutationTypes.SET_CURRENTWORKORDERID, event.data.WorkOrderID);
    router.push({ name: 'dviworkorderpackages', params: { workordernumber: event.data.WorkOrderNumber,workorderid: event.data.WorkOrderID } });
};
</script>

<style>
.container.no-pad {
    padding-right: 0;
    padding-left: 0;
}

button {
    border: none;
    background-color: transparent;
    padding: 1rem;
}

.input-group-text {
    background-color: #cee2fe;
    border: 1px solid #c3dcff;
}

.input-group input {
    border: 1px solid #c3dcff;
}

.table-bordered thead,
.table-bordered th {
    border: 1px solid #184b89 !important;
    background-color: #cee2fe;
    font-weight: normal;
}

.table-bordered tbody {
    border-top: 1px solid #184b89;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f2f8ff;
}

.table-bordered td {
    border: 1px solid #c3dcff;
}

.btn-outline {
    border: 1px solid #626262;
    color: #626262;
}
</style>
