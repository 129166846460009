import { useStore } from "../store";
import { MutationTypes } from '../store/mutations';

const ApiService = {
  async getStoreid(accessToken: string) {

    let storeid = localStorage.getItem('storeid') as string;
    if (storeid != null)
      return storeid;

    const response = await fetch(process.env.VUE_APP_AUTH0_USERINFO_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //const data =  await response.json();
    const data = await response.json();
    // console.log(data["http://protractor.com/storeid"]);

    storeid = data["http://protractor.com/storeid"];
    localStorage.setItem('storeid', storeid);

    return storeid;
  },
  async getVuexStoreid(accessToken: string) {
    const store = useStore();

    let storeid = store.state.storeid;
    if (storeid != '0')
      return storeid;

    const response = await fetch(process.env.VUE_APP_AUTH0_USERINFO_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //const data =  await response.json();
    const data = await response.json();
    // console.log(data["http://protractor.com/storeid"]);

    storeid = data["http://protractor.com/storeid"];
    store.commit(MutationTypes.SET_STOREID, storeid);

    return storeid;
  },

  async getVuexRole(accessToken: string) {
    const store = useStore();

    let role = store.state.role;
    if (role != '')
      return role;

    const response = await fetch(process.env.VUE_APP_AUTH0_USERINFO_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //const data =  await response.json();
    const data = await response.json();
    // console.log(data["http://protractor.com/role"]);

    role = data["http://protractor.com/role"];
    store.commit(MutationTypes.SET_ROLE, role);

    return role;
  },
  async getVuexUsername(accessToken: string):Promise<string> {
    const store = useStore();

   let username = store.state.username;
   if (username != '')
     return username;

    const response = await fetch(process.env.VUE_APP_AUTH0_USERINFO_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //const data =  await response.json();
    const data = await response.json();
   // console.log("h0:" + JSON.stringify(data));
    //h0:{"sub":"auth0|6283101b990b5b0068ec8b57","nickname":"gao_lizhong","name":"gao_lizhong@yahoo.com","picture":"https://s.gravatar.com/avatar/3b6f70ef72219f146d969bb03d0beac2?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fga.png","updated_at":"2023-08-28T21:28:12.441Z","email":"gao_lizhong@yahoo.com","email_verified":true,"http://protractor.com/storeid":"5fecbc20-0f0e-4a7c-bf41-040e11047e56","http://protractor.com/role":"StoreAdmin"}
    //console.log("h1:" + data["name"]);
    //console.log("h2:"+data["email"]);

    username = data["email"];
    store.commit(MutationTypes.SET_USERNAME, username);

    return username;
  },
  async getRole(accessToken: string) {
    let role = localStorage.getItem('role') as string;
    if (role != null)
      return role;

    const response = await fetch(process.env.VUE_APP_AUTH0_USERINFO_URL, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    //const data =  await response.json();
    const data = await response.json();

    role = data["http://protractor.com/role"];
    localStorage.setItem('role', role);

    return role;
  },

  async getStoreStatus(accessToken: string,storeid: string) {


    const response = await fetch(process.env.VUE_APP_NODEJS_BASE_URL + "stores/" + storeid, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    const storestatus = data.message.storestatus;
    //console.log("storestatus is :"+storestatus);
    return storestatus;
  },

  async getStoreName(accessToken: string,storeid: string) {


    const response = await fetch(process.env.VUE_APP_NODEJS_BASE_URL + "stores/" + storeid, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    const storename: string = data.message.storename;

    return storename;
  }
};

export default ApiService;