<template>
  <div>
    <div class="mb-5">
      <h1>This store is disabled</h1>
      <p>
        Please  <a id="qsLogoutBtn" href="#" class @click.prevent="logout">Log out</a> and then contact your storeadmin for help.
      </p>

    </div>
  </div>
</template>
<script lang="ts" setup>

import { useAuth0 } from "@auth0/auth0-vue";
const auth0 = useAuth0();
function logout() {
  auth0.logout({
    returnTo: window.location.origin
  });
}
</script>