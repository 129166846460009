import { ref } from 'vue';

/**
 * This composable is for vue components with ErrorModal usage
 * Note that below items are needed for vue components to use ErrorModal
 * This is for the logic reusability and future items of ErrorModal
 */
export const showErrorModal = ref(false);
export const errorContent = ref();
export const closeErrorModal = () => {
    showErrorModal.value = false;
	errorContent.value = '';
}
export const openErrorModal = (message: string) => {
    showErrorModal.value = true;
	errorContent.value = message;
    console.error(JSON.parse(message));
}