<template>
    <Toast />
    <Dialog v-model:visible="showErrorModal" modal header="Error" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" @update:visible="closeErrorModal">
        <p class="m-0">
            <Card style="width: 27rem; overflow: hidden">
                <template #header>
                    <div class="image-container"></div>
                </template>
                <template #title>Log ID: {{ error.logID }} <i class="pi pi-copy" style="cursor: pointer;" @click="copyLogID"></i></template>
                <template #subtitle>
                    <span>Context: {{ error.context }}</span>
                </template>
                <template #content>
                    <p class="m-0">
                        {{ error.message }}
                    </p>
                </template>
            </Card>
        </p>
    </Dialog>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useToast } from 'primevue/usetoast';
import { showErrorModal, errorContent, closeErrorModal } from '@/composables/useErrorLog';

declare const navigator: Navigator & {
  clipboard?: {
    writeText(text: string): Promise<void>;
  };
};

const toast = useToast();
const error = computed(() => { return JSON.parse(errorContent.value) });

const copyLogID = async () => {
    try {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(error.value.logID);
            return toast.add({ severity: 'success', summary: `Clipboard`, detail: 'Copied to clipboard.', life: 1000 });
        }
    } catch (error) {
        return toast.add({ severity: 'error', summary: `Clipboard`, detail: 'Failed copying Log ID.', life: 1000 });
    }
};
</script>

<style>
.image-container {
  /* Set the size and position of the container */
  width: 100%; /* Adjust width as needed */
  height: 1vh; /* Adjust height as needed */
  position: relative;
  /* Apply gradient background */
  background: linear-gradient(135deg, #ff7e5f 0%, #feb47b 100%);
}
</style>