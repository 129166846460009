<template>
    <ErrorModal />
    <Toast />
    <ConfirmDialog></ConfirmDialog>

    <div class="card-container inspection">
        <div class="inline-block">
            <h2 class="header">{{ workorderpackageheader }}</h2>
        </div>
        <div class="customer" v-if="!empty">
            <Button @click="gotoSummary" label="Review Inspection" class="p-button-rounded btn-primary sml blocked" />
        </div>
    </div>

    <div class="container">

        <div class="wo-details">
            <Button @click="handleGoBack($event)"
                class="p-button p-component p-button-rounded btn-primary">Workorder&nbsp;{{ workordernumber }}</Button>
            <p class="customer">
                <span><strong>Customer:</strong> {{ firstname }}&nbsp;{{ lastname }} </span>
                <span><strong>Vehicle:</strong> {{ color }}&nbsp;{{ make }}&nbsp;{{ model }} </span>
                <span><strong>Plate:</strong> {{plate}} </span>
                <span><strong>Odometer:</strong> {{UsageComputed}}</span>
            </p>
        </div>

        <div class="updateinspection" v-if="!empty">
            <div class="header row">
                <div class="title"><strong>Item</strong></div>
                <div class="resultGroup">
                    <div @click="handleSorting" class="result">
                            <strong :style="{color: sortResult != '' ? '#2196F3' : ''}">Result</strong>  
                            <span :style="{color: sortResult != '' ? '#2196F3' : ''}">
                                <i v-if="sortResult === ''" class="pi pi-fw pi-sort-alt"></i>
                                <i v-if="sortResult === 'asc'" class="pi pi-fw pi-sort-amount-up-alt"></i>
                                <i v-if="sortResult === 'desc'" class="pi pi-fw pi-sort-amount-down"></i>
                            </span>
                    </div>
                    <div class="allOk">
                        <span v-show="showResultIcon" @click="handleResultOK" style="cursor:pointer;" class="customer-badge status-null">Set Ok</span>
                    </div>
                </div>
            </div>

            <div v-for="(InspectionLine, index) in sortData(currentWorkOrderPackageInspectionLinesRef)" :key='index'
                class="inspection-item" @click="handleRowClick(InspectionLine, index)" :style="{ display: (InspectionLine.Type === 'Header' && sortResult !== '') ? 'none' : '' }">

                <div v-if="InspectionLine.Type === 'Header' && sortResult === ''" class="row" style="display:flex;justify-content:space-between;">
                    <div class="title">
                        <strong>{{ InspectionLine.Title }}</strong>
                    </div>
                </div>
                <div v-if="InspectionLine.Type === 'Line'" class="row" style="display:flex;justify-content:space-between;">
                    <div class="title">
                        {{ InspectionLine.Title }}
                        <span v-if="InspectionLine.Notes.length || InspectionLine.ShopNotes.length" class="customer-badge flag">Notes</span>
                        <span v-if="checkInspectionItemImages(InspectionLine.MediaKeys)" class="customer-badge flag">Images</span>
                        <span v-if="checkInspectionItemVideo(InspectionLine.MediaKeys)" class="customer-badge flag">Videos</span>
                    </div>

                    <div v-if="InspectionLine.Result" class="result"><span :class="'customer-badge status-' + InspectionLine.Result">{{
                        InspectionLine.Result }}</span></div>
                    <div v-else class="result"><span class="customer-badge status-null">+</span></div>
                    <div class="allOk"></div>
                </div>

                <Sidebar :visible="currentInspectionLineIndex == index" :block-scroll="false" :baseZIndex="10000"
                    position="right">

                    <template #header>
                        <div class="flex">
                            <Button @click="closeSidebar" class="p-sidebar-icon p-link">
                                <span class="pi pi-times" />
                            </Button>
                        </div>
                    </template>

                    <div class="prenex">
                        <Button label="Previous" class="p-button-rounded btn-secondary sml"
                            @click="previous" :disabled="PreviousButtonDisableStatus"/>
                        <Button label="Next" class="p-button-rounded btn-secondary sml"
                            @click="next" :disabled="NextButtonDisableStatus" />
                    </div>

                    <h3>{{ InspectionLine.Title }}</h3>

                    <div>
                        <SelectButton @change="() => {
                            InspectionLine.Comment = '';
                            InspectionLine.Result = InspectionLine.Result === null ? '' : InspectionLine.Result;
                        }" v-model="InspectionLine.Result" :options="status" class="select-status" />
                        <p class="mt-3 mb-0" v-if="InspectionLine.InputType != ''"><strong>Values:</strong></p>
                        <div class="sub-fields" v-if="InspectionLine.InputType != ''">
                            <span class="row">
                                <span>
                                    <label for="LF">LF</label>
                                    <InputText id="LF" v-model="InspectionLine.LF" :class="{error: v$.LF.$error}" @input="(event) => handleChangeInputLF(event)"/>
                                    <p v-if="v$.LF.$error">{{ v$.LF.$errors[0].$message }}</p>
                                </span>
                                <span>
                                    <label for="RF">RF</label>
                                    <InputText id="RF" v-model="InspectionLine.RF" :class="{error: v$.RF.$error}" @input="(event) => handleChangeInputRF(event)"/>
                                    <p v-if="v$.RF.$error">{{ v$.RF.$errors[0].$message }}</p>
                                </span>
                            </span>
                            <span class="units">
                                <p><strong>Unit: </strong> 

                                <Dropdown v-model="InspectionLine.Unit" :options="unit1" optionLabel="UnitText" optionValue="Unit" v-if="InspectionLine.InputType == 'TirePressure'" />
                                <Dropdown v-model="InspectionLine.Unit" :options="unit2" optionLabel="UnitText" optionValue="Unit" v-else />
                            </p>
                            </span>
                            <span class="row">
                                <span>
                                    <label for="LR">LR</label>
                                    <InputText id="LR" v-model="InspectionLine.LR" :class="{error: v$.LR.$error}" @input="(event) => handleChangeInputLR(event)"/>
                                    <p v-if="v$.LR.$error">{{ v$.LR.$errors[0].$message }}</p>
                                </span>
                                <span>
                                    <label for="RR">RR</label>
                                    <InputText id="RR" v-model="InspectionLine.RR" :class="{error: v$.RR.$error}" @input="(event) => handleChangeInputRR(event)"/>
                                    <p v-if="v$.RR.$error">{{ v$.RR.$errors[0].$message }}</p>
                                </span>
                            </span>
                        </div>
                        <p class="mt-3 mb-0" v-if="InspectionLine.Result == 'Suggested'"><strong>Context:</strong></p>
                        <Listbox id="listBoxSuggested" v-if="InspectionLine.Result == 'Suggested'" v-model="InspectionLine.Comment"
                            :options="suggestItem" />
                        <p class="mt-3 mb-0" v-if="InspectionLine.Result == 'Required'"><strong>Context:</strong></p>
                        <Listbox id="listBoxRequired" v-if="InspectionLine.Result == 'Required'" v-model="InspectionLine.Comment"
                            :options="RequiredItem" />
                        <p class="mt-3 mb-0"><strong>Notes:</strong></p>
                        <Textarea v-model="InspectionLine.Notes" rows="3" cols="50" maxlength="500" />
                        <p style="float: right;padding: 0.1rem 0 0 0;font-size: 0.875rem;">{{ InspectionLine.Notes.length }}/500</p>
                        <p class="mt-3 mb-0"><strong>Internal Notes</strong> (Not shown to customer(s)):</p>
                        <Textarea v-model="InspectionLine.ShopNotes" rows="3" cols="50" maxlength="500" />
                        <p style="float: right;padding: 0.1rem 0 0 0;font-size: 0.875rem;">{{ InspectionLine.ShopNotes.length }}/500</p>


                    </div>

                    <!-- Images -->
                    <template v-if="checkInspectionItemImages(InspectionLine.MediaKeys)">
                        <p class="mt-3 mb-0"><strong>Reference Images:</strong></p>
                        <div class="reference" style="border: 1px solid #ccc; position: relative;">
                            <ul style="list-style-type:none;padding:0;margin:0;">
                                <li v-for="(mediaSrc, index) in InspectionLine.MediaKeys" :key="'imageMedia-' + index" 
                                    :style="{
                                        display: !isVideo(mediaSrc) ? 'inline-block' : 'none',
                                        maxWidth:'calc(100% / 4)',
                                        paddingRight: '0.5rem',
                                        position: 'relative'
                                    }"
                                >
                                    <template v-if="!isVideo(mediaSrc)">
                                        <img @load="setLoaded(index)" v-lazy="mediaSrc" :key="'imgMedia-' + index" style="max-width:100%;" alt="Image" @error="event => handleImageError(event, '/default_no_image.png')">
                                        <Button v-show="imageLoaded[index]" :key="'btnImg-' + index" class="p-button p-button-rounded btn-danger sml delete" style="position: absolute; top: 0; right: 0;"
                                            @click="deleteImage(InspectionLine.WorkOrderPackageInspectionLineID, index, mediaSrc)">X</Button>
                                    </template>
                                </li>
                            </ul>
                        </div>
                    </template>
                    <!-- Videos -->
                    <template v-if="checkInspectionItemVideo(InspectionLine.MediaKeys)">
                        <p class="mt-3 mb-0"><strong>Reference Videos:</strong></p>
                        <div class="reference" style="border: 1px solid #ccc; position: relative;">
                            <ul style="list-style-type:none;padding:0;margin:0;">
                                <li v-for="(mediaSrc, index) in InspectionLine.MediaKeys" :key="'videoMedia-' + index"
                                    :style="{
                                        display: isVideo(mediaSrc) ? 'inline-block' : 'none',
                                        maxWidth:'calc(100% / 4)',
                                        paddingRight: '0.5rem',
                                        position: 'relative'
                                    }"
                                >
                                    <template v-if="isVideo(mediaSrc)">
                                        <img @load="setLoaded(index)" v-lazy="getActualImage(mediaSrc)" :key="'vidMedia-' + index" style="max-width:100%;" alt="Image" @error="event => handleImageError(event, '/default_no_image.png')">
                                        <Button v-show="imageLoaded[index]" :key="'btnVid-' + index" class="p-button p-button-rounded btn-danger sml delete" style="position: absolute; top: 0; right: 0;"
                                            @click="deleteVideo(InspectionLine.WorkOrderPackageInspectionLineID, index, mediaSrc)">X</Button>
                                    </template>
                                </li>
                            </ul>
                        </div>
                    </template>

                    <p class="mt-3 mb-0"><strong>Upload Images/Video:</strong></p>
                    <TabView>
                        <TabPanel header="Images">
                            <div class="p-fileupload p-fileupload-advanced p-component" v-if="getImageMedia(InspectionLine.MediaKeys).length == imageMaxCount">
                                <p style="background-color:#f1f0ef;padding:1.5rem 4rem; border-radius:5px;text-align:center;font-sie:1rem;">
                                    Maximum limit reached for image uploads
                                </p>
                            </div>
                            <div v-else class="p-fileupload p-fileupload-advanced p-component">
                                <FilePond name="myfilepond" ref="imagepond" class-name="filepond" :label-idle="`Capture / Select Images to Upload (Max of ${imageMaxCount} at < 5MB each)...`"
                                    allow-multiple="true" accepted-file-types="image/*" :maxFiles="imageMaxCount" maxFileSize="5MB" instantUpload="false" :maxParallelUploads="imageMaxCount"
                                    allow-Remove="true" allow-Process="false" allow-Revert="false"
                                    imageCropAspectRatio="1:1"
                                    allowImageResize="true"
                                    imageResizeTargetWidth="1280"
                                    imageResizeTargetHeight="720"
                                    imageResizeMode="contain"
                                    imageResizeUpscale="false"
                                    @init="handleFilePondInit"
                                    v-on:addfile="addImageFile"
                                    v-on:removefile="removeImageFile"
                                />
                                <div class="p-fileupload-buttonbar">
                                    <Button
                                        type="button"
                                        :disabled="!(selectedImages.length > 0)"
                                        @click="uploadImages(InspectionLine.MediaKeys)"
                                    >
                                        <span class="p-button-label">Upload</span>
                                        <span class="p-ink"></span>
                                    </Button>
                                    <Button
                                        type="button"
                                        :disabled="!(selectedImages.length > 0)"
                                        @click="clearImages"
                                    >
                                        <span class="p-button-label">Cancel</span><span class="p-ink"></span>
                                    </Button>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Video">
                            <div class="p-fileupload p-fileupload-advanced p-component" v-if="getVideoMedia(InspectionLine.MediaKeys).length == videoMaxCount">
                                <p style="background-color:#f1f0ef;padding:1.5rem 4rem; border-radius:5px;text-align:center;font-sie:1rem;">
                                    Maximum limit reached for video uploads
                                </p>
                            </div>
                            <div v-else class="p-fileupload p-fileupload-advanced p-component">
                                <VideoFilePond name="video" ref="videopond" class-name="filepond" :label-idle="`Capture / Select Videos to Upload (Max of ${videoMaxCount} at < 10MB each)...`"
                                    allow-multiple="true" accepted-file-types="video/*" :maxFiles="videoMaxCount" maxFileSize="10MB" instantUpload="false" :maxParallelUploads="videoMaxCount"
                                    allow-Remove="true" allow-Process="false" allow-Revert="false" @init="handleVideoPondInit"
                                    v-on:addfile="addVideoFile"
                                    v-on:removefile="removeVideoFile"
                                />
                                <div class="p-fileupload-buttonbar">
                                    <Button
                                        type="button"
                                        :disabled="!(selectedVideos.length > 0)"
                                        @click="uploadVideos(InspectionLine.MediaKeys)"
                                    >
                                        <span class="p-button-label">Upload</span>
                                        <span class="p-ink"></span>
                                    </Button>
                                    <Button
                                        type="button"
                                        :disabled="!(selectedVideos.length > 0)"
                                        @click="clearVideos"
                                    >
                                        <span class="p-button-label">Cancel</span><span class="p-ink"></span>
                                    </Button>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>

                    <div class="confirm">
                        <Button label="Close" class="p-button p-component p-button-rounded btn-secondary"
                            @click="closeSidebar" />
                    </div>

                </Sidebar>

            </div>

        </div>

        <div v-if="empty" class="container flex-grow-1">No inspection items found.</div>
        <Button @click="topFunction" id="topBtn" icon="pi pi-arrow-up" class="p-button-rounded" aria-label="Go to Top" />
    </div>

    
</template>



<script  lang="ts" setup>
import { computed, onMounted } from 'vue';
import { ref } from "@vue/reactivity";
import { WorkOrderPackage } from '../store/state';
import { useRouter } from 'vue-router';
import { useAuth0 } from "@auth0/auth0-vue";
import ApiService from '../service/api.service';
import { MutationTypes } from "../store/mutations";
import { useStore } from "../store";
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import * as _ from "lodash";
import { useConfirm } from "primevue/useconfirm";
import ErrorModal from './ErrorModal.vue';
import { openErrorModal } from '@/composables/useErrorLog';

// Import FilePond
import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';

const confirm = useConfirm();
const auth0 = useAuth0();
const router = useRouter();
const toast = useToast();
const workorderpackageid = computed(() => store.state.currentWorkOrderPackageID);
const workorderpackageheader = computed(() => store.state.currentWorkOrderPackageHeader);
const workorderid = ref('');
const workordernumber = ref('');
const lastname = ref('');
const firstname = ref('');
const make = ref('');
const model = ref('');
const color = ref('');
const plate = ref('');
const usage = ref();
const UsageComputed = computed(() => new Intl.NumberFormat().format(usage.value));
const PreviousButtonDisableStatus = ref(false);
const NextButtonDisableStatus = ref(false);
const currentInspectionLineIndex = ref();
const currentWorkOrderPackageInspectionLineID = ref();
const InspectionLinesLength = ref(0);
const showResultIcon = ref(false);
let currentWorkOrderPackage: WorkOrderPackage[] | undefined = [];
const currentWorkOrderPackageInspectionLinesRef = ref();
const dummyPostUrl = ref('');
dummyPostUrl.value = process.env.VUE_APP_NODEJS_BASE_URL + '/stores/test';

const suggestItem = ref([
    '1 - Part Is Close To The End Of Its Useful Life',
    '2 - To Address A Customer Need, Convenience Or Request',
    '3 - To Comply With Maintenance Recommended By The Vehicle\'s Original Equipment Manufacture',
    '4 - Technician\'s Recommendation Based on Substantial And Informed Experience',
    '5 - To Comply With Maintenance Recommended By AMRA/MAP'
]);

const RequiredItem = ref([
    'A - Part No Longer Performs Intended Purpose',
    'B - Part Does Not Meet A Design Specification (Regardless Of Performance)',
    'C - Part Is Missing'
]);


const status = ref(['OK', 'Suggested', 'Required']);
 const unit1 = ref([
    { UnitText: 'psi', Unit: 'PSI' },
    { UnitText: 'bar', Unit: 'BAR' }

 ]);
 const unit2 = ref([
    { UnitText: '/32"', Unit: 'NDS' },
    { UnitText: 'mm', Unit: 'Millimeter' }

]);

const LF = ref('');
const RF = ref('');
const LR = ref('');
const RR = ref('');

/**
 * Custom validation to check if value is empty string
 * @param value
 */
const valueIsRequired = (value) => {
    if (value !== '') {
        return true;
    }

    return false;
}

/**
 * Custom validation to check if number is valid
 * @param value 
 */
const isValidNumber = (value) => {
    if (value === '') {
        return true;
    }

    return !isNaN(value);
}

/**
 * Custom validation to check if number is 1 decimal place only
 * @param value 
 */
const decimalWithOneDecimalPlaceOrInteger = (value) => {
    if (value === '') {
        return true;
    }

    return /^\d+(\.\d)?$/.test(value); // allow whole or number with 1 decimal only, returns true if valid, and false if invalid
}

/**
 * Rules for vuelidate function
 */
const rules = computed(() => ({
  LF: {
    valueIsRequired: helpers.withMessage('Required', valueIsRequired),
    isValidNumber: helpers.withMessage('Number only', isValidNumber),
    decimalWithOneDecimalPlaceOrInteger: helpers.withMessage('max 1 decimal', decimalWithOneDecimalPlaceOrInteger)
  },
  RF: {
    valueIsRequired: helpers.withMessage('Required', valueIsRequired),
    isValidNumber: helpers.withMessage('Number only', isValidNumber),
    decimalWithOneDecimalPlaceOrInteger: helpers.withMessage('max 1 decimal', decimalWithOneDecimalPlaceOrInteger)
  },
  LR: {
    valueIsRequired: helpers.withMessage('Required', valueIsRequired),
    isValidNumber: helpers.withMessage('Number only', isValidNumber),
    decimalWithOneDecimalPlaceOrInteger: helpers.withMessage('max 1 decimal', decimalWithOneDecimalPlaceOrInteger)
  },
  RR: {
    valueIsRequired: helpers.withMessage('Required', valueIsRequired),
    isValidNumber: helpers.withMessage('Number only', isValidNumber),
    decimalWithOneDecimalPlaceOrInteger: helpers.withMessage('max 1 decimal', decimalWithOneDecimalPlaceOrInteger)
  },
}));

const v$ = useVuelidate(rules, { LF, RF, LR, RR });
const store = useStore();
const videoMaxCount = process.env.VUE_APP_VIDEO_MAX_COUNT;
const imageMaxCount = process.env.VUE_APP_IMAGE_MAX_COUNT;
const selectedImages = ref([]);
const selectedVideos = ref([]);
const videopond = ref(null);
const videoUploadCount = ref(0);
const currentVideoUploadCount = ref(0);
const imagepond = ref(null);
const imageUploadCount = ref(0);
const currentImageUploadCount = ref(0);
const imageLoaded = ref([]);
const Username = ref('');
const sortResult = ref('');
const currentDVIWO = computed(() => store.state.currentdviwoitem);
const empty = ref(false);
/**
 * Call load work order package and work order function in order to populate WO items
 */
onMounted(async () => {

    window.addEventListener('scroll', handleScroll);
    
    const accessToken = await auth0.getAccessTokenSilently();
    Username.value = await ApiService.getVuexUsername(accessToken);

    if (currentDVIWO.value !== undefined) {
        workorderid.value = currentDVIWO.value?.WorkOrderID;
        workordernumber.value = currentDVIWO.value?.WorkOrderNumber.toString();
        lastname.value = currentDVIWO.value?.LastName;
        firstname.value = currentDVIWO.value?.FirstName;
        make.value = currentDVIWO.value?.Make;
        model.value = currentDVIWO.value?.Model;
        color.value = currentDVIWO.value?.Color;
        plate.value = currentDVIWO.value?.Plate;
        usage.value = currentDVIWO.value?.InUsage;
    }

    currentWorkOrderPackage = currentDVIWO.value?.WorkOrderPackages?.filter(obj => obj.WorkOrderPackageID === workorderpackageid.value);

    if (currentWorkOrderPackage !== undefined) {
        const WorkOrderPackageInspectionLines = _.cloneDeep(currentWorkOrderPackage[0].WorkOrderPackageInspectionLines);
        currentWorkOrderPackageInspectionLinesRef.value = WorkOrderPackageInspectionLines;
        InspectionLinesLength.value = WorkOrderPackageInspectionLines.length;
    } else {
        currentWorkOrderPackageInspectionLinesRef.value = [];
    }

    if (currentWorkOrderPackageInspectionLinesRef.value.length === 0) {
        empty.value = true;
    }

    showResultIcon.value = !currentWorkOrderPackageInspectionLinesRef.value
        .filter((item) => item.Type === 'Line')
        .every((item) => item.Result.trim() !== '');
});

/**
 * To display tob button
 */
const handleScroll = () => {
    const topButton = document.getElementById("topBtn");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        topButton.style.display = "flex";
    } else {
        topButton.style.display = "none";
    }
}

/**
 * Makes the page to move at the top
 */
const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

const handleGoBack = (event) => {
    handleAutoSave(event);
    router.push({ name: 'dviworkorderpackages', params: { workordernumber: workordernumber.value, workorderid: workorderid.value } });
}

const handleAutoSave = () => {
    if (currentWorkOrderPackage !== undefined) {
        currentWorkOrderPackage[0].WorkOrderPackageInspectionLines = currentWorkOrderPackageInspectionLinesRef.value;
        store.commit(MutationTypes.UPDATE_DVIWOPACKAGE, { WorkOrderNumber: workordernumber.value, WorkOrderPackageID: workorderpackageid.value, WorkOrderPackage: currentWorkOrderPackage[0] });
    }
}//end of handleAutoSave

const selectRow = (WorkOrderPackageInspectionLineID, index: number) => {
    currentWorkOrderPackageInspectionLineID.value = WorkOrderPackageInspectionLineID;
    currentInspectionLineIndex.value = index;
    
    if (sortResult.value !== '') {
        const lastLineItem = InspectionLinesLength.value - currentWorkOrderPackageInspectionLinesRef.value.filter((item) => item.Type === 'Header').length;
        if (currentInspectionLineIndex.value == 0) {
            PreviousButtonDisableStatus.value = true;
            NextButtonDisableStatus.value = false;
        } else if (currentInspectionLineIndex.value == (lastLineItem - 1)) {
            PreviousButtonDisableStatus.value = false;
            NextButtonDisableStatus.value = true;
        } else {
            PreviousButtonDisableStatus.value = false;
            NextButtonDisableStatus.value = false;
        }
    }
    else if (currentInspectionLineIndex.value == 1 && currentWorkOrderPackageInspectionLinesRef.value[0].Type === 'Header') {
        PreviousButtonDisableStatus.value = true;
        NextButtonDisableStatus.value = false;
    } else if (index == 0) {
        PreviousButtonDisableStatus.value = true;
        NextButtonDisableStatus.value = false;
    } else if (index == (InspectionLinesLength.value - 1)) {
        PreviousButtonDisableStatus.value = false;
        NextButtonDisableStatus.value = true;
    } else {
        PreviousButtonDisableStatus.value = false;
        NextButtonDisableStatus.value = false;
    }
}

const processUnitandLFRFLRRR = () => {
    const currentItem = currentWorkOrderPackageInspectionLinesRef.value[currentInspectionLineIndex.value];

    if (currentItem.LF === '' && currentItem.LR === '' && currentItem.RF === '' && currentItem.RR === '' && currentItem.Unit === '' && currentItem.UnitText === '') {
        return true;
    }

    if (currentItem.Unit == "PSI")
        currentItem.UnitText = "psi";

    if (currentItem.Unit == "BAR")
        currentItem.UnitText = "bar";

    if (currentItem.Unit == "Millimeter")
        currentItem.UnitText = "mm";

    if (currentItem.Unit == "NDS")
        currentItem.UnitText = '/32"';

    LF.value = currentItem.LF;
    RF.value = currentItem.RF;
    LR.value = currentItem.LR;
    RR.value = currentItem.RR;

    if (currentItem.InputType != '' && (currentItem.LF === '' || currentItem.LR === '' || currentItem.RF === '' || currentItem.RR === '' || currentItem.Unit === '' || currentItem.UnitText === ''))
        return false;

    return true;
}

const closeSidebar = async () => {
    imageLoaded.value = [];
    if (!processUnitandLFRFLRRR() && !await v$.value.$validate())
        return toast.add({ severity: 'error', summary: 'Fail', detail: 'Please enter valid values.', life: 3000 });

    clearImages();
    clearVideos();

    handleAutoSave();

    showResultIcon.value = !currentWorkOrderPackageInspectionLinesRef.value
        .filter((item) => item.Type === 'Line')
        .every((item) => item.Result.trim() !== '');

    currentInspectionLineIndex.value = 10000;
}

const previous = async () => {
    imageLoaded.value = [];
    if (!processUnitandLFRFLRRR() && !await v$.value.$validate())
        return toast.add({ severity: 'error', summary: 'Fail', detail: 'Please enter valid values.', life: 3000 });

    clearImages();
    clearVideos();

    handleAutoSave();
    currentInspectionLineIndex.value = validateItemType(currentInspectionLineIndex.value - 1, 'previous');
    
    const currentItem = currentWorkOrderPackageInspectionLinesRef.value[currentInspectionLineIndex.value];
    currentWorkOrderPackageInspectionLineID.value = currentItem.WorkOrderPackageInspectionLineID;
    
    if (sortResult.value !== '') {
        if (currentInspectionLineIndex.value == 0) {
            PreviousButtonDisableStatus.value = true;
            NextButtonDisableStatus.value = false;
        } else {
            PreviousButtonDisableStatus.value = false;
            NextButtonDisableStatus.value = false;
        }
    }
    if (currentInspectionLineIndex.value == 1 && currentWorkOrderPackageInspectionLinesRef.value[0].Type === 'Header') {
        PreviousButtonDisableStatus.value = true;
        NextButtonDisableStatus.value = false;
    } else if (currentInspectionLineIndex.value == 0) {
        PreviousButtonDisableStatus.value = true;
        NextButtonDisableStatus.value = false;
    } else {
        PreviousButtonDisableStatus.value = false;
        NextButtonDisableStatus.value = false;
    }
}

const next = async () => {
    imageLoaded.value = [];
    if (!processUnitandLFRFLRRR() && !await v$.value.$validate())
        return toast.add({ severity: 'error', summary: 'Fail', detail: 'Please enter valid values.', life: 3000 });

    clearImages();
    clearVideos();
   
    handleAutoSave();
    currentInspectionLineIndex.value = validateItemType(currentInspectionLineIndex.value + 1, 'next');
    
    currentWorkOrderPackageInspectionLineID.value = currentWorkOrderPackageInspectionLinesRef.value[currentInspectionLineIndex.value].WorkOrderPackageInspectionLineID;
    if (sortResult.value !== '') {
        const lastLineItem = InspectionLinesLength.value - currentWorkOrderPackageInspectionLinesRef.value.filter((item) => item.Type === 'Header').length;
        if (currentInspectionLineIndex.value == (lastLineItem - 1)) {
            PreviousButtonDisableStatus.value = false;
            NextButtonDisableStatus.value = true;
        } else {
            PreviousButtonDisableStatus.value = false;
            NextButtonDisableStatus.value = false;
        }
    }
    else if (currentInspectionLineIndex.value == (InspectionLinesLength.value - 1)) {
        PreviousButtonDisableStatus.value = false;
        NextButtonDisableStatus.value = true;
    } else {
        PreviousButtonDisableStatus.value = false;
        NextButtonDisableStatus.value = false;
    }
}


/**
 * Apply to all ok status
 */
 const handleResultOK = () => {
    let BlankResultInspectionLines=currentWorkOrderPackageInspectionLinesRef.value.filter(obj => obj.Result == "")
    if (BlankResultInspectionLines != 0) {
        confirm.require({
            message: 'Do you want to set all remaining items to OK?',
            header: 'Please confirm action',
            icon: 'pi pi-exclamation-circle',
            acceptClass: 'p-button-primary',
                accept: async () => {
                currentWorkOrderPackageInspectionLinesRef.value.map(obj => {
                    if (obj.Result == '' && obj.Type == 'Line') {
                        obj.Result = 'OK';
                    }
                })
                if (currentWorkOrderPackage !== undefined) {
                    currentWorkOrderPackage[0].WorkOrderPackageInspectionLines.map(obj => {
                        if (obj.Result == "") {
                            obj.Result = 'OK';
                        }
                    })
                }
                handleAutoSave();
                showResultIcon.value = false;
                toast.add({ severity: 'success', summary: 'Set', detail: 'All remaining items have been set to OK', life: 3000 });
            },//end of accept
        }); //end of confirm
    }//end if
}

const imageServerOptions = {
    server: {
        // eslint-disable-next-line
        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            
            // fieldName is the name of the input field
            // file is the actual file object to send
            const accessToken = await auth0.getAccessTokenSilently();
            const storeid = await ApiService.getVuexStoreid(accessToken);
            const filetype = file.type.split("/")[1];

            // get secure url from our server
            try {
                const response = await fetch(process.env.VUE_APP_NODEJS_BASE_URL + "s3/getpresignedputobjecturl/" + storeid + "/" + workordernumber.value + "/" + filetype, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                const data = await response.json();
                const { url } = data;
                if (response.status > 400) {
                    throw new Error(JSON.stringify(data.error));
                }
                const imageUrl = url.split('?')[0];
                const request = new XMLHttpRequest();
                request.open('PUT', url);

                // Should call the progress method to update the progress to 100% before calling load
                // Setting computable to false switches the loading indicator to infinite mode
                request.upload.onprogress = (e) => {
                    progress(e.lengthComputable, e.loaded, e.total);
                };

                // Should call the load method when done and pass the returned server file id
                // this server file id is then used later on when reverting or restoring a file
                // so your server knows which file to return without exposing that info to the client
                request.onload = function () {
                    if (request.status >= 200 && request.status < 300) {
                        // the load method accepts either a string (id) or an object
                        load(request.responseText);

                        //update/append InspectionLines ref
                        const latestInspectionLineMediaKeys = currentWorkOrderPackageInspectionLinesRef.value.filter(obj => obj.WorkOrderPackageInspectionLineID === currentWorkOrderPackageInspectionLineID.value)[0].MediaKeys.concat(imageUrl);
                        currentWorkOrderPackageInspectionLinesRef.value.filter(obj => obj.WorkOrderPackageInspectionLineID === currentWorkOrderPackageInspectionLineID.value)[0].MediaKeys = latestInspectionLineMediaKeys;

                        //update/append currentWorkOrderPackage in memory
                        if (currentWorkOrderPackage !== undefined) {
                            currentWorkOrderPackage[0].WorkOrderPackageInspectionLines.filter(obj => obj.WorkOrderPackageInspectionLineID === currentWorkOrderPackageInspectionLineID.value)[0].MediaKeys = latestInspectionLineMediaKeys;
                        }

                        //update VUEx media key state
                        store.commit(MutationTypes.UPDATE_MEDIAKEYS, { WorkOrderNumber: workordernumber.value, WorkOrderPackageID: workorderpackageid.value, WorkOrderPackageInspectionLineID: currentWorkOrderPackageInspectionLineID.value, MediaKeys: latestInspectionLineMediaKeys });
                        checkImageProgress();
                    } else {
                        // can call the error method if something is wrong, should exit after
                        error('error uploading image');
                    }
                };

                request.send(file);
                // Should expose an abort method so the request can be cancelled
                return {
                    abort: () => {
                        // This function is entered if the user has tapped the cancel button
                        request.abort();

                        // Let FilePond know the request has been cancelled
                        abort();
                    },
                };
            } catch (e) {
                openErrorModal(e.message);
            }
        },
    },//end of server
};

/**
 * Image file pond initialization
 */
const handleFilePondInit = () => {
    imagepond.value[0]._pond.setOptions(imageServerOptions);
}

const videoServerOptions = {
    server: {
        // eslint-disable-next-line
        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            const accessToken = await auth0.getAccessTokenSilently();
            const formData = new FormData();
            try {
                const response = await fetch(process.env.VUE_APP_NODEJS_BASE_URL + 'videos/token/' + store.state.storeid, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                const generatedToken = await response.json();
                if (response.status > 400) {
                    throw new Error(JSON.stringify(generatedToken.error));
                }
                formData.append('source_video', file, file.name);
                formData.append('folder_id', generatedToken.folder_id);
                formData.append('token', generatedToken.token);
            } catch (e) {
                openErrorModal(e.message);
            }

            try {
                const oReq = new XMLHttpRequest();
                oReq.open('POST', process.env.VUE_APP_SPROUTVIDEO_API_BASE_URL + "videos", true);
                oReq.upload.onprogress = (e) => {
                    progress(e.lengthComputable, e.loaded, e.total);
                }
                oReq.onreadystatechange = () => {
                    if (oReq.readyState === XMLHttpRequest.DONE) {
                        if (oReq.status === 201 || oReq.status === 200) {
                            const actualData = JSON.parse(oReq.response);
                            setVideoMediaKeys(actualData);
                            load();
                            checkVideoProgress();
                        } else {
                            abort();
                        }
                    }
                }
                oReq.send(formData);
            } catch (error) {
                // handle network error
                console.error("Error uploading video:", error);
            }
        }
    }
};

/**
 * Video file pond initialization
 */
const handleVideoPondInit = () => {
    videopond.value[0]._pond.setOptions(videoServerOptions);
}

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginImageTransform, FilePondPluginImageResize);


/**
 * Instantiation of VideoFilePond Component
 */
const VideoFilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginMediaPreview);

/**
 * Before calling the processFiles function, verify whether the combined length of mediaKey and selectedImages is less than max count.
 * @param mediaKeys 
 */
const uploadImages = async (mediaKeys) => {
    if (getImageMedia(mediaKeys).length + selectedImages.value.length <= imageMaxCount) {
        imageUploadCount.value = selectedImages.value.length;
        imagepond.value[0].processFiles();
    } else {
        toast.add({ severity: 'error', summary: 'Image Upload', detail: `You can upload only ${imageMaxCount} image files.`, life: 3000 });
    }
}

/**
 * Verify first if imagepond instance exists then remove the files in selectedImages and in imagepond instance
 */
const clearImages = () => {
    if (imagepond.value !== null && imagepond.value.length !== 0) {
        const imagePondInstance = imagepond.value[0];
        imagePondInstance.removeFiles();
        selectedImages.value = [];
    }
}

/**
 * Before calling the processFiles function, verify whether the combined length of mediaKey and selectedVideos is less than max count.
 * @param mediaKeys 
 */
const uploadVideos = (mediaKeys) => {
    if (getVideoMedia(mediaKeys).length + selectedVideos.value.length <= videoMaxCount) {
        videoUploadCount.value = selectedVideos.value.length;
        videopond.value[0].processFiles();
    } else {
        toast.add({ severity: 'error', summary: 'Video Upload', detail: `You can upload only ${videoMaxCount} video files.`, life: 3000 });
    }
};

/**
 * Verify first if videopond instance exists then remove the files in selectedVideos and in videopond instance
 */
const clearVideos = () => {
    if (videopond.value !== null && videopond.value.length !== 0) {
        const videoPondInstance = videopond.value[0];
        videoPondInstance.removeFiles();
        selectedVideos.value = [];
    }
}

/**
 * Set video data in vuex
 * @param data 
 */
const setVideoMediaKeys = (data) => {
    const video = `https://api-files.sproutvideo.com/file/${data.id}/${data.security_token}/`;
    const thumbnail = data?.assets?.thumbnails[0];
    const videoMedia = `${thumbnail}|${video}`;

    //update/append InspectionLines ref
    const latestInspectionLineMediaKeys = currentWorkOrderPackageInspectionLinesRef.value.filter(obj => obj.WorkOrderPackageInspectionLineID === currentWorkOrderPackageInspectionLineID.value)[0].MediaKeys.concat(videoMedia);
    currentWorkOrderPackageInspectionLinesRef.value.filter(obj => obj.WorkOrderPackageInspectionLineID === currentWorkOrderPackageInspectionLineID.value)[0].MediaKeys = latestInspectionLineMediaKeys;

    //update/append currentWorkOrderPackage in memory
    if (currentWorkOrderPackage !== undefined) {
        currentWorkOrderPackage[0].WorkOrderPackageInspectionLines.filter(obj => obj.WorkOrderPackageInspectionLineID === currentWorkOrderPackageInspectionLineID.value)[0].MediaKeys = latestInspectionLineMediaKeys;
    }

    //update VUEx media key state
    //store has already been initiated 
    store.commit(MutationTypes.UPDATE_MEDIAKEYS, { WorkOrderNumber: workordernumber.value, WorkOrderPackageID: workorderpackageid.value, WorkOrderPackageInspectionLineID: currentWorkOrderPackageInspectionLineID.value, MediaKeys: latestInspectionLineMediaKeys });
}

/**
 * Verify first if error is null then include add file id.
 * @param error 
 * @param imageItem 
 */
const addImageFile = (error, imageItem) => {
    if (error === null) {
        selectedImages.value.push({file_id: imageItem.id});
    }
}

/**
 * Remove the image file.
 * @param error 
 * @param imageItem 
 */
const removeImageFile = (error, imageItem) => {
    selectedImages.value = selectedImages.value.filter((item) => item.file_id !== imageItem.id);
}

/**
 * Verify first if error is null then add the file id.
 * @param error 
 * @param videoItem 
 */
const addVideoFile = (error, videoItem) => {
    if (error === null) {
        selectedVideos.value.push({file_id: videoItem.id});
    }
}

/**
 * Remove the video file.
 * @param error 
 * @param videoItem 
 */
const removeVideoFile = (error, videoItem) => {
    selectedVideos.value = selectedVideos.value.filter((item) => item.file_id !== videoItem.id);
}

/**
 * Set the image src with the default no image.
 * @param event 
 * @param srcImg 
 */
const handleImageError = (event, srcImg) => {
    event.target.src = srcImg;
}

/**
 * Get actual image from media item value.
 * @param mediaItem 
 */
const getActualImage = (mediaItem) => {
    return mediaItem.split('|')[0];
}

/**
 * Get actual video from media item value.
 * @param mediaItem 
 */
const getActualVideo = (mediaItem) => {
    return mediaItem.split('|')[1];
}

/**
 * Adding a toast once done with the uploads.
 */
const checkImageProgress = () => {
    currentImageUploadCount.value++;
                    
    if (currentImageUploadCount.value === imageUploadCount.value) {
        toast.add({ severity: 'success', summary: 'Image Upload', detail: 'The image(s) have been successfully uploaded.', life: 3000 });
        currentImageUploadCount.value = 0;
        imageUploadCount.value = 0;
        clearImages();
    }
}

/**
 * Adding a toast once done with the uploads
 */
const checkVideoProgress = () => {
    currentVideoUploadCount.value++;
                    
    if (currentVideoUploadCount.value === videoUploadCount.value) {
        toast.add({ severity: 'success', summary: 'Video Upload', detail: 'The video(s) have been successfully uploaded.', life: 3000 });
        currentVideoUploadCount.value = 0;
        videoUploadCount.value = 0;
        clearVideos();
    }
}

/**
 * A function that returns boolean for the Images label to appear
 * @param mediaItems 
 */
const checkInspectionItemImages = (mediaItems) => {
    if (mediaItems.length !== 0) {
        return mediaItems.some((item: string) => !(item.includes("|")));
    }

    return false;
}

/**
 * A function that returns boolean for the Videos label to appear
 * @param mediaItems 
 */
const checkInspectionItemVideo = (mediaItems) => {
    if (mediaItems.length !== 0) {
        return mediaItems.some((item: string) => item.includes("|"));
    }

    return false;
}

/**
 * Get all image media in MediaKeys
 * @param mediaItems 
 */
const getImageMedia = (mediaItems) => {
    return mediaItems.filter((item: string) => !item.includes("|"));
}

/**
 * Get all video media in MediaKeys
 * @param mediaItems 
 */
const getVideoMedia = (mediaItems) => {
    return mediaItems.filter((item: string) => item.includes("|"));
}

/**
 * Handle on input event of subfield LF
 * @param event 
 */
const handleChangeInputLF = (event) => {
    LF.value = event.target.value;
    v$.value.LF.$validate();
}

/**
 * Handle on input event of subfield LR
 * @param event 
 */
const handleChangeInputLR = (event) => {
    LR.value = event.target.value;
    v$.value.LR.$validate();
}

/**
 * Handle on input event of subfield RF
 * @param event 
 */
const handleChangeInputRF = (event) => {
    RF.value = event.target.value;
    v$.value.RF.$validate();
}

/**
 * Handle on input event of subfield RR
 * @param event 
 */
const handleChangeInputRR = (event) => {
    RR.value = event.target.value;
    v$.value.RR.$validate();
}

/**
 * Delete actual
 * @param WorkOrderPackageInspectionLineID 
 * @param index 
 * @param media 
 */
const deleteVideo = async (WorkOrderPackageInspectionLineID: string, index: number, media: string) => {
    deleteVideoInVuex(index);
    //delete image from db through integration API
    await deleteVideoIntegrationAPI(WorkOrderPackageInspectionLineID, media);
    await deleteVideoInSproutAPI(media);
}

/**
 * Delete Video in integration API
 * @param WorkOrderPackageInspectionLineID 
 * @param media 
 */
const deleteVideoIntegrationAPI = async (WorkOrderPackageInspectionLineID: string, media: string) => {
    let mediadata = {
        MediaKey: media,
        Username:Username.value
    };

    const accessToken = await auth0.getAccessTokenSilently();
    const storeid = await ApiService.getVuexStoreid(accessToken);
    const url = process.env.VUE_APP_NODEJS_BASE_URL + "dviworkorders/s3mediakey/" + storeid + "/" + WorkOrderPackageInspectionLineID;

    try {
        const response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(mediadata),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
    } catch (e) {
        openErrorModal(e.message);
    }
}

/**
 * Delete video in vuex
 * @param WorkOrderPackageInspectionLineID 
 * @param index 
 */
const deleteVideoInVuex = (index: number) => {
    imageLoaded.value.splice(index, 1);
    //delete image from array ref memory
    const updatedMediaKeys = [...currentWorkOrderPackageInspectionLinesRef.value[currentInspectionLineIndex.value].MediaKeys];
    updatedMediaKeys.splice(index, 1);
    currentWorkOrderPackageInspectionLinesRef.value[currentInspectionLineIndex.value].MediaKeys = updatedMediaKeys;

    //delete image from VUEX state
    if (currentWorkOrderPackage !== undefined) {
        currentWorkOrderPackage[0].WorkOrderPackageInspectionLines = currentWorkOrderPackageInspectionLinesRef.value;
        store.commit(MutationTypes.UPDATE_DVIWOPACKAGE, { WorkOrderNumber: workordernumber.value, WorkOrderPackageID: workorderpackageid.value, WorkOrderPackage: currentWorkOrderPackage[0] });
    }
}

/**
 * Delete video in sprout API
 * @param videoID 
 */
const deleteVideoInSproutAPI = async (media: string) => {
    const videoUrl = getActualVideo(media);
    
    // get video id using video url
    const videoArray = videoUrl.split('/');
    const videoID = videoArray[videoArray.length - 3];

    try {
        const accessToken = await auth0.getAccessTokenSilently();
        const response = await fetch(process.env.VUE_APP_NODEJS_BASE_URL + 'videos/' + videoID, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
    } catch (e) {
        openErrorModal(e.message);
    }
}

/**
 * Delete actual image
 * @param WorkOrderPackageInspectionLineID 
 * @param Imageindex 
 * @param ImageFullUrl 
 */
const deleteImage = async (WorkOrderPackageInspectionLineID, index: number, ImageFullUrl) => {
    deleteImageInVuex(index);
    //delete image from db through integration API
    await deleteImageIntegrationAPI(WorkOrderPackageInspectionLineID, ImageFullUrl);
    await deleteImageInAWSS3(ImageFullUrl);
}

/**
 * Delete image in vuex
 * @param ImageFullUrl 
 */
const deleteImageInVuex = (index: number) => {
    imageLoaded.value.splice(index, 1);
    //delete image from array ref memory
    const updatedMediaKeys = [...currentWorkOrderPackageInspectionLinesRef.value[currentInspectionLineIndex.value].MediaKeys];
    updatedMediaKeys.splice(index, 1);
    currentWorkOrderPackageInspectionLinesRef.value[currentInspectionLineIndex.value].MediaKeys = updatedMediaKeys;

    //delete image from VUEX state
    if (currentWorkOrderPackage !== undefined) {
        currentWorkOrderPackage[0].WorkOrderPackageInspectionLines = currentWorkOrderPackageInspectionLinesRef.value;
        store.commit(MutationTypes.UPDATE_DVIWOPACKAGE, { WorkOrderNumber: workordernumber.value, WorkOrderPackageID: workorderpackageid.value, WorkOrderPackage: currentWorkOrderPackage[0] });
    }
}

/**
 * Delete image in API server
 * @param WorkOrderPackageInspectionLineID 
 * @param ImageFullUrl 
 */
const deleteImageIntegrationAPI = async (WorkOrderPackageInspectionLineID: string, ImageFullUrl: string) => {
    let mediadata = {
        MediaKey: ImageFullUrl,
        Username: Username.value
    };

    const accessToken = await auth0.getAccessTokenSilently();
    const storeid = await ApiService.getVuexStoreid(accessToken);
    const url = process.env.VUE_APP_NODEJS_BASE_URL + "dviworkorders/s3mediakey/" + storeid + "/" + WorkOrderPackageInspectionLineID;

    try {
        const response = await fetch(url, {
            method: 'PATCH',
            body: JSON.stringify(mediadata),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
    } catch (e) {
        openErrorModal(e.message);
    }
}

/**
 * Delete image in AWS S3
 * @param ImageFullUrl 
 */
const deleteImageInAWSS3 = async (ImageFullUrl: string) => {
    const accessToken = await auth0.getAccessTokenSilently();
    const storeid = await ApiService.getVuexStoreid(accessToken);
    const deletedMediaKey = ImageFullUrl.split("/")[5];
    const s3url = process.env.VUE_APP_NODEJS_BASE_URL + "s3/deleteobject/" + storeid + "/"  + workordernumber.value + "/" + deletedMediaKey;
    try {
        const response = await fetch(s3url, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
    } catch (e) {
        openErrorModal(e.message);
    }
}

/**
 * Returns if mediaValue is video or not
 * @param mediaValue 
 */
const isVideo = (mediaValue: string) => {
    return mediaValue.includes("|");
}

/**
 * Set index with true value once the image is loaded
 * @param index 
 */
const setLoaded = (index) => {
  imageLoaded.value[index] = true;
}

/**
 * Go to Summary page
 */
const gotoSummary = () => {
    router.push({ name: 'dvisummaryworkorderpackage', params: { workorderpackageid: workorderpackageid.value, workorderpackageheader: workorderpackageheader.value }  });
}

/**
 * Apply click event only for inspection items with type line
 * @param InspectionLine 
 * @param index 
 */
const handleRowClick = (InspectionLine, index: number) => {
    if (InspectionLine.Type === 'Line') {
        selectRow(InspectionLine.WorkOrderPackageInspectionLineID, index);
    }
}

/**
 * Validate if the item type is header or line
 * @param newIndex 
 * @param action 
 */
const validateItemType = (newIndex: number, action: string) => {
    const futureItem = currentWorkOrderPackageInspectionLinesRef.value[newIndex];
    if (futureItem.Type === 'Header') {
        if (action === 'next') {
            return newIndex + 1;
        }

        if (action === 'previous') {
            return newIndex - 1;
        }
    }

    return newIndex;
}

/**
 * Changes the sorting value if the user clicks on the result sorting icon
 */
const handleSorting = () => {
    switch(sortResult.value) {
        case '': sortResult.value = 'asc'
            break;
        case 'asc': sortResult.value = 'desc'
            break;
        case 'desc': sortResult.value = ''
            break;
    }
}

/**
 * Put the item on the last if the type is header
 * desc, required is the highest
 * asc, required is the lowest
 * @param a 
 * @param b 
 */
const customSort = (a, b) => {
    let order = { Required: 1, Suggested: 2, OK: 3, '': 4 };
    if (sortResult.value === 'desc') {
        order = { Required: 4, Suggested: 3, OK: 2, '': 1 };
    }
    if (a.Type === 'Header') return 1;
    if (b.Type === 'Header') return -1;
    return order[a.Result] - order[b.Result];
};

/**
 * Return original sorting which is by Rank
 * Rank is the property which considered as index in API response
 * @param a 
 * @param b 
 */
const originalSorting = (a, b) => {
    return a.Rank - b.Rank; 
}

/**
 * Sort data original, custom ascending and descending
 * @param data 
 */
const sortData = (data) => {
    if(!data)
        return [];
    if (data && sortResult.value === '')
        return data.sort(originalSorting);
    return data.sort(customSort);
}
</script>

<style>
.p-progressbar-label {
    font-size: xx-small;
}

button.p-sidebar-close.p-sidebar-icon.p-link {
    display: none !important;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#topBtn {
    display: none;
    position: fixed;
    z-index: 99;
    outline: none;
    cursor: pointer;
}
</style>
