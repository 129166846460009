<template>
  <div class="top-bar-wrap">
    <div :class="isAuthenticated ? 'topbar auth' : 'topbar'">
      <div class="mainlogo" v-if="!isAuthenticated && !isLoading">
        <img class="app-logo" src="../assets/img/protractorNew2.svg" alt="Protractor">
      </div>

      <div class="mainlogo" v-if="isAuthenticated">
        <img v-show="isHomePage" class="app-logo" src="../assets/img/protractorNew2.svg" alt="Protractor">
        <h2 v-show="isVINScannerPage">VIN Scanner</h2>
        <h2 v-show="isDVIPage">Digital Vehicle Inspection</h2>
      </div>

      <!-- LOGIN UNAUTHENTICATED -->
      <CustomMenubar v-if="!isAuthenticated && !isLoading" />
      <!-- LOGIN WITHOUT SELECTED FEATURE -->
      <CustomMenubar v-if="isHomePage && isAuthenticated" :user="user" />
      <!-- LOGIN WITH SELECTED FEATURE - VINSCANNER -->
      <CustomMenubar v-if="isVINScannerPage && isAuthenticated" :model="vinscannerTabs" :user="user" />
      <!-- LOGIN WITH SELECTED FEATURE - DVI -->
      <CustomMenubar v-if="isDVIPage && isAuthenticated" :model="DVITabs" :user="user" />
  
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import CustomMenubar from './CustomMenubar.vue';
const auth0 = useAuth0();
const router = useRouter();
const isAuthenticated = ref(auth0.isAuthenticated);
const isLoading = ref(auth0.isLoading);
const user = ref(auth0.user);
/**
 * List of tabs for VINScanner
 */
const vinscannerTabs = ref([
  {
    label: 'Home',
    path: '/'
  },
  {
    label: 'Work Orders',
    path: '/workorders'
  },
]);

/**
 * List of tabs for DVI
 */
const DVITabs = ref([
  {
    label: 'Home',
    path: '/'
  },
  {
    label: 'Work Orders',
    path: '/dviworkorders'
  },
]);

const isHomePage = computed(() => router.currentRoute.value.path === '/');
const isVINScannerPage = computed(() => router.currentRoute.value.path !== '/' && !router.currentRoute.value.path.includes('dvi') && !router.currentRoute.value.path.includes('results'));
const isDVIPage = computed(() => router.currentRoute.value.path !== '/' && router.currentRoute.value.path.includes('dvi'));

</script>
