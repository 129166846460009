<template>
  <ErrorModal />
  <Toast />
  <ConfirmDialog></ConfirmDialog>
  
  <div class="card-container inspection">
    <div class="inline-block">
      <h2 class="header">Inspections for WO# {{ currentWorkOrderNumber }}
        <Button style="margin-bottom: .1rem !important;" type="button" icon="pi pi-refresh" class="p-button-text p-button-rounded" @click="loadWorkOrderPackages" />
      </h2>
    </div>
    <div class="customer" v-if="items.length > 0">
      <Button :disabled="!submitWorkOrderStatus" @click="submitWorkOrder" label="Inspection(s) Complete" class="p-button p-component p-button-rounded btn-primary sml odo"/>
    </div>
  </div>

  <div class="container flex-grow-1 workorders">

    <div class="wo-details">
      <p class="customer">
        <span><strong>Customer:</strong> {{ firstname }}&nbsp;{{ lastname }} </span>
        <span><strong>Vehicle:</strong> {{ color }}&nbsp;{{ make }}&nbsp;{{ model }} </span>
        <span><strong>Plate:</strong> {{plate}} </span>
        <span><strong>Odometer:</strong> {{ InUsageComputed }}</span>
      </p>
      <span v-if="items.length > 0">
        <Button @click="openUpdateOdometerDialog" label="Update Odometer" class="p-button p-component p-button-rounded btn-secondary sml odo" style="margin-right: .3rem;" />
      </span>
    </div>

    <Dialog v-model:visible="updateOdometerDialog" header="Update Odometer" :modal="true" class="p-fluid">
      <div class="info">
        <p><strong>Vehicle:</strong> {{ color }} {{ make }} {{ model }}, {{plate}}</p>
        <p class="lead text-muted"><strong>Odometer In<span class="req">*</span></strong>
          <!-- <InputNumber inputId="integeronly" /> -->
          <InputNumber inputId="integeronly" id="InUsage" v-model="InUsage" :class="{ 'p-invalid': v$.InUsage.$error }" required="true" />
          <span class="error" v-if="v$.InUsage.$error">{{ v$.InUsage.$errors[0].$message }}</span>
        </p>
        <p class="lead text-muted"><strong>Odometer Out<span class="req">*</span></strong>
          <InputNumber inputId="integeronly" id="OutUsage" v-model="OutUsage" :class="{ 'p-invalid': v$.OutUsage.$error }" required="true" />
          <span class="error" v-if="v$.OutUsage.$error">{{ v$.OutUsage.$errors[0].$message }}</span>
        </p>

      </div>
      
      <template #footer>
        <Button label="Update" class="p-button p-component p-button-rounded btn-primary" @click="updateOdometer" />
      </template>
    </Dialog>

    <DataTable :value="items" :paginator="true" :rows="10" stripedRows
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]" breakpoint="640px"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :filters="filters" selectionMode="single"
      @rowSelect="onRowSelect" :loading="loading" dataKey="WorkOrderPackageID" v-if="!empty">

      <template #loading>
        <span>Loading inspections <i class="pi pi-spin pi-spinner"></i></span>
      </template>

      <!-- <Column field="WorkOrderPackageID" header="WorkOrderPackageID" :sortable="true"></Column> -->
      <Column field="WorkOrderPackageHeader" header="Type" :sortable="true" style="width:60%"></Column>
      <Column header="Status" :sortable="true" style="width:20%">
        <template #body="slotProps">
          <span v-if="checkAllInspectionResults(slotProps.data)">Items Complete</span>
          <span v-else-if="checkAllInspectionResultsSome(slotProps.data)">In Progress</span>
          <span v-else>Pending</span>
        </template>
      </Column>
      <Column class="status" header=" " style="width:20%">
        
        <template #body="slotProps" >
          <Button label="Summary" class="p-button-rounded btn-primary sml blocked" @click="gotoSummary(slotProps.data)" v-if="checkAllInspectionResults(slotProps.data)"/>
        </template>

      </Column>
    </DataTable>

    <div v-if="empty" class="container flex-grow-1">No inspections found.</div>

  </div>

</template>

<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { ref } from "@vue/reactivity";
import ApiService from '../service/api.service';
import { onMounted, onBeforeMount, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "../store";
import { MutationTypes } from "../store/mutations";
import { FilterMatchMode } from 'primevue/api';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { numeric, required, helpers } from '@vuelidate/validators';
import { useConfirm } from "primevue/useconfirm";
import ErrorModal from './ErrorModal.vue';
import { openErrorModal } from '@/composables/useErrorLog';

const auth0 = useAuth0();
const items = ref([]);

const filters = ref({
  'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const role = ref('');
const storestatus = ref('');
const loading = ref(false);
const empty = ref(false);
const updateOdometerDialog = ref(false);
const router = useRouter();
const store = useStore();
const confirm = useConfirm();
const toast = useToast();
const currentWorkOrderNumber = computed(() => store.state.currentWorkOrderNumber);
const currentWorkOrderID = computed(() => store.state.currentWorkOrderID);
const storeid = computed(() => store.state.storeid);
store.commit(MutationTypes.SET_CURRENTDVIWO_BYID, currentWorkOrderNumber.value);
const currentDVIWO = computed(() => store.state.currentdviwoitem);
const lastname = currentDVIWO.value?.LastName;
const firstname = currentDVIWO.value?.FirstName;
const make = currentDVIWO.value?.Make;
const model = currentDVIWO.value?.Model;
const color = currentDVIWO.value?.Color;
const plate = currentDVIWO.value?.Plate;
const InUsage = ref(0);
const OutUsage = ref(0);
const InUsageComputed = computed(() => new Intl.NumberFormat().format(currentDVIWO.value?.InUsage ?? 0));

const rules = computed(() => ({
  InUsage: {numeric: helpers.withMessage('Odometer In must be number', numeric), required: helpers.withMessage('Value required for Odometer In', required) },
  OutUsage: { numeric: helpers.withMessage('Odometer Out must be number', numeric), required: helpers.withMessage('Value required for Odometer Out', required) }
}));
const v$ = useVuelidate(rules, { InUsage, OutUsage });

/**
 * checkAllInspectionResults will return true if all items have Status
 */
const submitWorkOrderStatus = computed(() => {
  let submitValue = true;
  for (let itemIndex = 0; itemIndex < items.value.length; itemIndex++) {
    if (checkAllInspectionResults(items.value[itemIndex]) === false) {
      submitValue = false;
      break;
    }
  }

  return submitValue
});

/**
 * On before mount sets the initial data
 */
onBeforeMount(async () => {
  const auth0 = useAuth0();
  const accessToken = await auth0.getAccessTokenSilently();
  role.value = await ApiService.getVuexRole(accessToken);
  storestatus.value = await ApiService.getStoreStatus(accessToken, storeid.value);
  if (storestatus.value == 'Disable')
    router.push('/storedisable');
}
);

/**
 * Call load work order package and work order function in order to populate WO items
 */
onMounted(async () => {
  const dviworkoderpackages = currentDVIWO.value?.WorkOrderPackages;
  if (dviworkoderpackages !== null && dviworkoderpackages !== undefined) {
    items.value = dviworkoderpackages;
  } else {
    loadWorkOrderPackages();
  }
});

/**
 * Load work order packages and assign data by VUE mutation
 */
const loadWorkOrderPackages = async () => {
  loading.value = true;
  const store = useStore();
  const accessToken = await auth0.getAccessTokenSilently();
  const WOurl = process.env.VUE_APP_NODEJS_BASE_URL + "dviworkorders/" + storeid.value + "/" + currentWorkOrderID.value;
  try {
    const response = await fetch(WOurl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    loading.value = false;
    const data = await response.json();
    if (response.status > 400) {
      throw new Error(JSON.stringify(data.error));
    }
    const ServicePackages = data.message.ServicePackages;

    //in state, set WorkOrderPackages into current WorkOrder's WorkOrderPackages[] property
    store.commit(MutationTypes.SET_DVIWOPACKAGESONCURRENTWO, ServicePackages);
    items.value = ServicePackages;
    InUsage.value = data.message.InUsage;
    OutUsage.value = data.message.OutUsage;
    store.commit(MutationTypes.UPDATE_ODOMETER, { WorkOrderNumber: currentWorkOrderNumber.value, InUsage: InUsage.value, OutUsage: OutUsage.value });

    if ((items.value.length) < 1)
      empty.value = true;
    else
      empty.value = false;
  } catch (e) {
    openErrorModal(e.message);
  }
}

/**
 * Sends query to update page via router push
 * @param event 
 */
const onRowSelect = (event) => {
  store.commit(MutationTypes.SET_CURRENTWORKORDERPACKAGEID, event.data.WorkOrderPackageID);
  store.commit(MutationTypes.SET_CURRENTWORKORDERPACKAGEHEADER, event.data.WorkOrderPackageHeader);
  router.push({ name: 'dviupdateworkorderpackage', params: { workorderpackageid: event.data.WorkOrderPackageID, workorderpackageheader: event.data.WorkOrderPackageHeader } });
};

/**
 * Sends query to summary page via router push
 * @param prod 
 */
const gotoSummary = async (prod) => {
  const rowdata = { ...prod };
  store.commit(MutationTypes.SET_CURRENTWORKORDERPACKAGEID, rowdata.WorkOrderPackageID);
  store.commit(MutationTypes.SET_CURRENTWORKORDERPACKAGEHEADER, rowdata.WorkOrderPackageHeader);
  router.push({ name: 'dvisummaryworkorderpackage', params: { workorderpackageid: rowdata.WorkOrderPackageID, workorderpackageheader: rowdata.WorkOrderPackageHeader } });
}

/**
 * Function to open update odometer dialog
 */
const openUpdateOdometerDialog = () => {
  InUsage.value = currentDVIWO.value?.InUsage ?? 0;
  OutUsage.value = currentDVIWO.value?.OutUsage ?? 0;
  updateOdometerDialog.value = true;
}

/**
 * Update odometer
 */
 const updateOdometer = async () => {
  if (!await v$.value.$validate()) return;
  updateOdometerDialog.value = false;
  let odometerdata = {
    InUsage: InUsage.value,
    OutUsage: OutUsage.value
  };
  const accessToken = await auth0.getAccessTokenSilently();
  const url = process.env.VUE_APP_NODEJS_BASE_URL + "dviworkorders/odometer/" + storeid.value + "/" + currentWorkOrderID.value;
  try {
    const response: Response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(odometerdata),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
    });
    const data = await response.json();
    if (response.status > 400) {
      throw new Error(JSON.stringify(data.error));
    }
    
    //update vuex state for odometer
    const store = useStore();
    store.commit(MutationTypes.UPDATE_ODOMETER, { WorkOrderNumber: currentWorkOrderNumber.value, InUsage: InUsage.value, OutUsage: OutUsage.value });

    v$.value.$reset();
    toast.add({ severity: 'success', summary: 'Successful', detail: 'Updated odometer in and out', life: 3000 });
  } catch (e) {
    openErrorModal(e.message);
  }
}

/**
 * Iterate all inspection items with Type Line
 * and verify if all items have existing Result status value
 * @param inspectionItems 
 */
const checkAllInspectionResults = (inspectionItems) => {
  return inspectionItems.WorkOrderPackageInspectionLines
    .filter((item) => item.Type === 'Line')
    .every((item) => item.Result.trim() !== '');
}

/**
 * Iterate all inspection items with type line
 * and verify if some items have Result value
 * @param inspectionItems 
 */
const checkAllInspectionResultsSome = (inspectionItems) => {
  return inspectionItems.WorkOrderPackageInspectionLines
    .filter((item) => item.Type === 'Line')
    .some((item) => item.Result.trim() !== '');
}

/**
 * Submit workorder consists of 2 asynchronous method, save inspections and complete workorder
 */
 const submitWorkOrder = async () => {
  confirm.require({
    message: 'Are you sure you want to mark this Work Order Completed?',
    header: 'Please confirm submit',
    icon: 'pi pi-exclamation-circle',
    acceptClass: 'p-button-primary',
    accept: async () => {
      await completeWorkorder();
    }
  });
}

/**
 * Submit a work order as complete then redirect to DVIWorkorders page
 */
const completeWorkorder = async () => {
  const accessToken = await auth0.getAccessTokenSilently();
  const url = process.env.VUE_APP_NODEJS_BASE_URL + "dviworkorders/workflow/" + storeid.value + "/" + currentWorkOrderID.value;
  try {
    const response: Response = await fetch(url, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });
    const data = await response.json();
    if (response.status > 400) {
      throw new Error(JSON.stringify(data.error));
    }
    router.push({ name: 'dviworkorders', params: { completed: 'completed' } });
  } catch (e) {
    openErrorModal(e.message);
  }
}
</script>
<style>
.container.no-pad {
  padding-right: 0;
  padding-left: 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 1rem;
}

.input-group-text {
  background-color: #cee2fe;
  border: 1px solid #c3dcff;
}

.input-group input {
  border: 1px solid #c3dcff;
}

.table-bordered thead,
.table-bordered th {
  border: 1px solid #184b89 !important;
  background-color: #cee2fe;
  font-weight: normal;
}

.table-bordered tbody {
  border-top: 1px solid #184b89;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f8ff;
}

.table-bordered td {
  border: 1px solid #c3dcff;
}

.btn-outline {
  border: 1px solid #626262;
  color: #626262;
}
</style>