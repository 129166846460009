<template>
  <p class="lead text-muted"><strong>Store name:</strong> {{ storename }}</p>
  <p class="lead text-muted"><strong>User role:</strong> {{ role }}</p>
</template>

<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";
import ApiService from '../service/api.service';
import { onBeforeMount } from 'vue';
import { ref } from "@vue/reactivity";

const storeid = ref('');
const storename = ref('');
const role = ref('');

onBeforeMount(async () => {
  const auth0 = useAuth0();
  const accessToken = await auth0.getAccessTokenSilently();
  storeid.value = await ApiService.getVuexStoreid(accessToken);
  storename.value = await ApiService.getStoreName(accessToken, storeid.value);
  role.value = await ApiService.getRole(accessToken);
});
</script>
