<template>
    <ErrorModal />

    <div class="card-container">
        <div class="inline-block">
            <h2 class="header">Workorder: #{{ workordernumber }}</h2>
        </div>
    </div>

    <div class="container flex-grow-1 updateorder">

        <form @submit.prevent="onSubmit" class="card p-3 border" id="form">
            <div class="form-group">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="fullname">Name</span>
                    </div>
                    <InputText type="text" class="border form-control" :value="fullname" readonly />
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="plate">Plate #</span>
                    </div>
                    <InputText type="text" class="border form-control" v-model="plate" />
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="make">Make</span>
                    </div>
                    <InputText type="text" class="border form-control" :value="make" readonly />
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="model">Model</span>
                    </div>
                    <InputText type="text" class="border form-control" :value="model" readonly />
                </div>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="color">Color</span>
                    </div>
                    <InputText type="text" class="border form-control" :value="color" readonly />
                </div>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="vin">VIN</span>
                    </div>
                    <InputText type="text" class="border form-control" v-model="vin" @focus="vinfocus = true"
                        @blur="vinfocus = false" />
                    <span v-if="v$.vin.$error">{{ v$.vin.$errors[0].$message }}</span>
                </div>

                <div class="button-group">
                    <label for="files" class="p-button p-button-rounded btn-primary mt-3" @click="capture = true">Capture/Select Image <i v-if="capture"
                            class="pi pi-spin pi-spinner"></i></label>
                    <input type="file" id="files" class="files" @change="filesChange($event.target.files)" />
                </div>
                <div class="button-group bottom">
                    <Button type="submit" class="p-button-rounded btn-secondary ml-2" label="Update" />
                    <Button label="Cancel" class="p-button-rounded btn-secondary ml-2" @click="handleCancel" />
                </div>

            </div>

            <div class="image" v-if="previewphoto">
                <img :src="previewphoto" alt="" ref="photo" />
            </div>

        </form>

    </div>
</template>

<script  lang="ts" setup>
import { computed } from 'vue';
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from 'vue-router';
import { useAuth0 } from "@auth0/auth0-vue";
import ApiService from '../service/api.service';
import { MutationTypes } from "../store/mutations";
import { useStore } from "../store";
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength, helpers } from '@vuelidate/validators';
import ErrorModal from './ErrorModal.vue';
import { openErrorModal } from '@/composables/useErrorLog';

const route = useRoute();
const router = useRouter();

const workordernumber = route.params.workordernumber;
const store = useStore();
store.commit(MutationTypes.SET_CURRENTWO_BYID, workordernumber);
let currentWO = store.getters.getCurrentWO;
const serviceitemid = currentWO?.ServiceItemID;
const lastname = currentWO?.LastName;
const firstname = currentWO?.FirstName;
const make = currentWO?.Make;
const model = currentWO?.Model;
const color = currentWO?.Color;
const vin = ref();
vin.value = currentWO?.VIN;
const plate = ref();
plate.value = currentWO?.Plate;
const previewphoto = ref();
previewphoto.value = null;
const photo = ref('');
const loading = ref(false);
const capture = ref(false);
const vinfocus = ref();
const auth0 = useAuth0();
const fullname = computed(() => firstname + " " + lastname);
const rules = computed(() => ({
    vin: { required: helpers.withMessage('VIN cannot be empty', required), minLength: helpers.withMessage('VIN must be 17 characters', minLength(17)), maxLength: helpers.withMessage('VIN must be 17 characters', maxLength(17)) },
}));
const v$ = useVuelidate(rules, { vin });

async function onSubmit() {
    if (!await v$.value.$validate()) return;
    const accessToken = await auth0.getAccessTokenSilently();
    const storeid = await ApiService.getVuexStoreid(accessToken);
    const WOurl = process.env.VUE_APP_NODEJS_BASE_URL + "workorders/" + storeid + "/" + serviceitemid;
    try {
        const response = await fetch(WOurl, {
            method: 'PATCH',
            body: JSON.stringify({
                VIN: vin.value,
                PlateNumber: plate.value,
            }),
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        const data = await response.json();
        if (response.status > 400) {
            throw new Error(JSON.stringify(data.error));
        }
        const store = useStore();
        store.commit(MutationTypes.UPDATE_VIN, { ServiceItemID: serviceitemid, VIN: vin.value, Plate: plate.value });
        router.go(-1);
    } catch (e) {
        openErrorModal(e.message);
    }
}

function handleCancel() {
    router.push({ name: 'workorders' });
}

function filesChange(fileList) {
    loading.value = true;
    const url = process.env.VUE_APP_VINOCR_URL;
    // handle file changes
    const formData = new FormData();
    if (!fileList.length) return;
    let reader = new FileReader;
    reader.onloadstart = () => { loading.value = true; console.log(loading.value); };
    reader.onloadend = () => { loading.value = false; console.log(loading.value); };
    reader.onload = (event) => {
        //optimize image
        const imgElement = document.createElement("img");
        imgElement.src = event.target.result;
        imgElement.onload = function (e) {
            capture.value = false;
            const canvas = document.createElement("canvas");
            const MAX_WIDTH = 480;
            const scaleSize = MAX_WIDTH / e.target.width;
            canvas.width = MAX_WIDTH;
            canvas.height = e.target.height * scaleSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
            const srcEncoded = ctx.canvas.toDataURL(e.target, "image/jpeg");
            // you can send srcEncoded to the server, base64
            previewphoto.value = srcEncoded;
            //convert base64 to blob
            const base64 = srcEncoded;
            var img2 = base64.replace("data:", "").replace(/^.+,/, "");
            const byteCharacters = atob(img2);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const img = new Blob([byteArray], { type: 'image/jpeg' });
            formData.append('file', img);
            //call VINOCR API
            var xHttp = new XMLHttpRequest();
            xHttp.open("POST", url, false);
            xHttp.send(formData);
            var jsonResponse = JSON.parse(xHttp.responseText);
            if (jsonResponse.status === 'SUCCESS')
                vin.value = jsonResponse.vin_captured;
            else
                vin.value = 'FAILED';
        };
    };
    reader.readAsDataURL(fileList[0]);
}
</script>

<style>
label {
    margin: 0.25rem 0 0;
}

.form-control,
.form-control:disabled,
.form-control[readonly] {
    background-color: #f2f8ff;
    border: 1px solid #c3dcff !important;
    opacity: 1;
}

.input-group-text {
    width: 4.5rem;
}

.button-container .btn {
    margin-right: 0.5rem;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
