import { createRouter as createVueRouter, createWebHistory, Router } from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import WorkOrders from "../views/WorkOrders.vue";
import UpdateOrder from "../views/UpdateOrder.vue";
import StoreDisable from "../views/StoreDisable.vue";
import { createAuthGuard } from "@auth0/auth0-vue";
import { App } from 'vue';

import DVIWorkOrders from "../views/DVIWorkOrders.vue";
import DVIWorkOrderPackages from "../views/DVIWorkOrderPackages.vue";
import DVISummaryWorkOrderPackage from "../views/DVISummaryWorkOrderPackage.vue";
import DVIUpdateWorkOrderPackage from "../views/DVIUpdateWorkOrderPackage.vue";

export function createRouter(app: App): Router {
  return createVueRouter({
    routes: [
      {
        path: "/",
        name: "home",
        component: Home
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/workorders",
        name: "workorders",
        component: WorkOrders,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/updateorder",
        name: "updateorder",
        component: UpdateOrder,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/storedisable",
        name: "storedisable",
        component: StoreDisable,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/dviworkorders",
        name: "dviworkorders",
        component: DVIWorkOrders,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/dviworkorderpackages",
        name: "dviworkorderpackages",
        component: DVIWorkOrderPackages,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/dviupdateworkorderpackage",
        name: "dviupdateworkorderpackage",
        component: DVIUpdateWorkOrderPackage,
        beforeEnter: createAuthGuard(app)
      },
      {
        path: "/dvisummaryworkorderpackage",
        name: "dvisummaryworkorderpackage",
        component: DVISummaryWorkOrderPackage,
        beforeEnter: createAuthGuard(app)
      }
    ],
    history: createWebHistory()
  })
}
