<template>
  <div id="app" class="d-flex flex-column h-100">
    <nav-bar />
    <!-- <div class="container flex-grow-1"> -->
    <div class="outer" :class="$route.name">
      <error />
      <div>
        <router-view />
      </div>
    </div>
    <Footer class="footer text-center">
      <!-- <div class="logo"></div>
      <p>

      </p> -->
    </Footer>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import Error from "./components/Error";
import Footer from "./components/Footer";

export default {
  components: {
    NavBar,
    Error,
    Footer
  }
};
</script>
