<template>
  <ErrorModal />
  <div class="content" v-if="!isAuthenticated">
    <Card>
      <template #title>Welcome to the<br />Protractor Companion App</template>
      <template #content>
        <p>Protractor works to bring leading edge technology to automotive aftermarket repair shops and tire stores.</p>
        <p><strong>VIN scanner</strong><br />Enables you to capture a picture of the VIN and save it directly to the work order.</p>
        <p><i>Planned features</i> - Digital Vehicle Inspection, Reporting, Time Clock, to name a few.</p>
        <h3>Not a Protractor user?</h3>
        <a href="https://www.protractorsoftware.com/contact/" target="_blank"><Button label="Book a Demo" class="p-button-rounded primary" icon="pi pi-arrow-right" iconPos="right" /></a>
        <h3>Want to learn more?</h3>
        <a href="https://www.protractorsoftware.com" target="_blank"><Button label="Visit Protractor.com" class="p-button-rounded primary" icon="pi pi-arrow-right" iconPos="right" /></a>
        <h3>Already a Protractor user?</h3>
        <a href="https://www.protractorsoftware.com/contact/" target="_blank"><Button label="Request a Login" class="p-button-rounded primary" icon="pi pi-arrow-right" iconPos="right" /></a>
      </template>
    </Card>
  </div>

  <template>
    <span v-show="false">
      {{ listAllFeatures() }}
    </span>
  </template>

  <template v-if="isAuthenticated && filteredFeatureNames.length !== 0">
    <div class="content secondary auth" v-if="filteredFeatureNames.includes('VINScanner')">
      <router-link :to="{ name: 'workorders', params: { url: '/workorders' }}" @click="(event: MouseEvent) => captureFeature(event, 'vinscanner')">
        <Card>
            <template #title>VIN Scanner</template>
            <template #content>
                <p>No more squinting and typing. Capture a picture of the VIN and save it to the work order - simple.</p>
            </template>
        </Card>
      </router-link>
    </div>

    <div class="content primary auth" v-if="filteredFeatureNames.includes('DVI')">
      <router-link :to="{ name: 'dviworkorders', params: { url: '/dviworkorders' }}" @click="(event: MouseEvent) => captureFeature(event, 'dvi')">
        <Card>
            <template #title>Digital Vehicle Inspection</template>
            <template #content>
                <p>True mobile inspections. Add images and video to support all findings, and send to customers' devices.</p>
            </template>
        </Card>
      </router-link>
    </div>
  </template>

  <!-- <div class="next-steps">
    <h2 class="my-5 text-center">What can I do next?</h2>

    <div class="row">
      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="https://auth0.com/docs/connections">
            <font-awesome-icon icon="link" class="mr-2" />Configure other identity providers
          </a>
        </h6>
        <p>Auth0 supports social providers as Facebook, Twitter, Instagram and 100+, Enterprise providers as Microsoft Office 365, Google Apps, Azure, and more. You can also use any OAuth2 Authorization Server.</p>
      </div>

      <div class="col-md"></div>

      <div class="col-md-5 mb-4">
        <h6 class="mb-3">
          <a href="https://auth0.com/docs/multifactor-authentication">
            <font-awesome-icon icon="link" class="mr-2" />Enable Multifactor Authentication
          </a>
        </h6>
        <p>Add an extra layer of security by enabling Multi-factor Authentication, requiring your users to provide more than one piece of identifying information. Push notifications, authenticator apps, SMS, and DUO Security are supported.</p>
      </div>
    </div> -->
    
  <!-- </div> -->
</template>


<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { ref, inject } from "vue";
import ApiService from "@/service/api.service";
import Button from 'primevue/button';
import ErrorModal from '../views/ErrorModal.vue';
import { openErrorModal } from '@/composables/useErrorLog';

const posthog = inject("posthog");
const storeid = ref('');
const role = ref('');
const storeDetails = ref([]);
const allFeatures = ref([]);
const userRoles = ref([]);
const filteredFeatureNames = ref([]);
const featuresFetched = ref(false);
const { isAuthenticated, user  } = useAuth0();

/**
 * Actual fetching of data
 * Note that useAuth0 doesn't work properly on onMounted and onBeforeMount
 * isAuthenticated always return false if used by native vue hooks
 * That is why I end up calling this on Vue component
 */
const listAllFeatures = async () => {
  if (isAuthenticated.value === true && !featuresFetched.value) {
    const auth0 = useAuth0();
    const accessToken = await auth0.getAccessTokenSilently();
    storeid.value = await ApiService.getVuexStoreid(accessToken);
    role.value = await ApiService.getVuexRole(accessToken);
    storeDetails.value = await getStoreDetails(accessToken);
    allFeatures.value = await getAllFeatures(accessToken);
    userRoles.value = await getAllUserRoles(accessToken);
    
    setFeatures();
    featuresFetched.value = true;
  }
  return;
}

/**
 * Filtering of features based on store features and user role
 */
const setFeatures = () => {
  const actualRole = userRoles.value.filter((item) => item.userrole === role.value)[0];

  // if the Store detail has no managedfeatureids then select all non-manageable feature in features table
  if (storeDetails.value.managedfeatureids === null) {
    // get all non-manageable features, it is accessible by default
    const filteredFeatures = allFeatures.value.filter((feature) => feature.manageable === false);
    // get only the feature names for simplicity
    filteredFeatureNames.value = filteredFeatures.map((item) => item.featurename);
    return;
  }
  // managedfeatureids exists in store details
  // if the Store detail has managedfeatureids but the role has no availablefeatureids, then use the managedfeatureids as features
  if (actualRole.availablefeatureids === null) {
    const storemanagedfeatureids = storeDetails.value.managedfeatureids.split(',').map(Number);
    const filteredFeatures = allFeatures.value.filter((feature) => storemanagedfeatureids.includes(feature.featureid) || feature.manageable === false);
    // get only the feature names for simplicity
    filteredFeatureNames.value = filteredFeatures.map((item) => item.featurename);
    return;
  }

  // if the Store detail has managedfeatureids and the role has availablefeatureids, return the matching ids in user role details and store details
  const roleavailablefeatureids = actualRole.availablefeatureids.split(',').map(Number);
  const storemanagedfeatureids = storeDetails.value.managedfeatureids.split(',').map(Number);

  // select only the matching ids between user role and store details
  const filteredfeatureids = roleavailablefeatureids.filter((item) => storemanagedfeatureids.includes(item));
  // select the feature items in feature table based on filteredfeatureids or non-manageable features, it is accessible by default
  const filteredFeatures = allFeatures.value.filter((feature) => filteredfeatureids.includes(feature.featureid) || feature.manageable === false);
  // get only the feature names for simplicity
  filteredFeatureNames.value = filteredFeatures.map((item) => item.featurename);
}

/**
 * API request for getting store details
 * @param accessToken 
 */
const getStoreDetails = async (accessToken: string) => {
  try {
    const response = await fetch(process.env.VUE_APP_NODEJS_BASE_URL + "stores/" + storeid.value, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
    return data.message;
  } catch (e) {
    openErrorModal(e.message);
  }
}

/**
 * API request for getting all feaures
 * @param accessToken 
 */
const getAllFeatures = async (accessToken: string) => {
	try {
		const response: Response = await fetch(process.env.VUE_APP_NODEJS_BASE_URL + "sa/features", {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
		});
		const data = await response.json();
    if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
		return data.message;
	} catch (e) {
    openErrorModal(e.message);
	}
}

/**
 * API request for getting all user roles
 * @param accessToken 
 */
const getAllUserRoles = async (accessToken: string) => {
	const url = process.env.VUE_APP_NODEJS_BASE_URL + "sa/userroles";
	try {
		const response: Response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
		});
		const data = await response.json();
    if (response.status > 400) {
			throw new Error(JSON.stringify(data.error));
		}
		return data.message;
	} catch (e) {
		openErrorModal(e.message);
	}
}

const captureFeature = (event: MouseEvent, tabName: string) => {
  event.preventDefault();
  const email = user.value.email;
  posthog.capture(`${tabName}_tab_clicked`, { email: email });
}
</script>
